import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from '@progress/kendo-data-query'
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxProps,
} from '@progress/kendo-react-dropdowns'
import { FC, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { availableLanguages } from '../../atoms/AvailableLanguages'
import { Lookup } from '../../types'

interface Props {
  name: string
  handleItemClick: (item: Lookup, name: string) => void
  comboBoxKey?: number
}

export const LanguagesPickerCombobox: FC<Props & ComboBoxProps> = ({
  disabled,
  name,
  handleItemClick,
  defaultValue,
}) => {
  const languages = useRecoilValue(availableLanguages)
  const [filteredLanguages, setFilteredLanguages] =
    useState<Lookup[]>(languages)

  const handleLanguageChoose = ({ target }: ComboBoxChangeEvent) => {
    handleItemClick(target.value, name)
  }

  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    return filterBy(languages, filter)
  }

  const filterChange = (event: {
    filter: FilterDescriptor | CompositeFilterDescriptor
  }) => {
    setFilteredLanguages(filterData(event.filter))
  }

  return (
    <ComboBox
      className="grid-autocomplete"
      popupSettings={{ popupClass: 'autocomplete-popup' }}
      clearButton={true}
      data={filteredLanguages}
      textField="name"
      suggest
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder="Search language"
      filterable
      onFilterChange={filterChange}
      onChange={handleLanguageChoose}
    />
  )
}
