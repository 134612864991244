import { Grid, GridColumn } from '@progress/kendo-react-grid'
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns'
import { cloneElement, FC, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'

import { GetPrivateForSuggestionParams } from '../../../api/model/providers/IngredientsProvider'
import InputSearch from '../../../components/InputSearch'
import { model } from '../../../api/model'
import Dropdown from '../../../components/Dropdown'
import {
  GridHeaderCell,
  GridIngredientName,
  IngredientsPickerCombobox,
  LanguagesPickerCombobox,
  SuggestedIngredientActions,
} from '../../../components/KendoGridElements'
import IngredientOccurrences from '../../../components/KendoGridElements/IngredientOccurrences'
import Modal from '../../../components/Modal'
import Pagination from '../../../components/Pagination'
import {
  IngredientToAdd,
  Lookup,
  PrivateIngredientsForSuggestion,
  SelectedLookups,
  SuggestedIngredient,
  SynonymToAdd,
} from '../../../types'
import { httpErrorHandler } from '../../../api/HttpError'
import SuggestedAddIngredientModal from '../../../components/AddNewIngredientModal/SuggestedIngredientsModal'
import IngredientDetailModal from '../../../components/IngredientDetailModal'
import OfficialAddIngredientModal from '../../../components/AddNewIngredientModal/OfficialIngredientsModal'
import { useGetIngredientDetailsForModalByNameQuery } from '../../../components/IngredientDetailModal/DetailPeek/useGetDetailPeekIngredientsData'
import filters from './filters.json'

import styles from './SuggestedIngredients.module.scss'

const FETCH_LIMIT = 20

let ingredientsForJoin = {} as SelectedLookups
let selectedLanguages = {} as SelectedLookups

const SuggestedIngredients: FC = () => {
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')
  const [moderatedFilter, setModeratedFilter] = useState(filters.moderated[1])
  const [page, setPage] = useState(1)
  const [fetchedData, setFetchedData] =
    useState<PrivateIngredientsForSuggestion>()
  const [newIngredientModal, setNewIngredientModal] = useState(false)
  const [selectedIngredient, setSelectedIngredient] =
    useState<SuggestedIngredient>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [fetchedLanguages, setFetchedLanguages] = useState<Lookup[]>([])
  const [selectedLanguage, setSelectedLanguage] = useState<Lookup>({
    id: 5,
    name: 'English(UK)',
  })
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [currentIngredientName, setCurrentIngredientName] = useState<string>('')
  const [showAddOfficialIngModal, setShowAddOfficialIngModal] =
    useState<boolean>(false)
  const [officialIngredientToAddName, setOfficialIngredientToAddName] =
    useState<string>('')
  const [fetchedLists, setFetchedLists] = useState<Lookup[]>([])
  const [selectedFilterList, setSelectedFilterList] = useState<Lookup>({
    id: 1,
    name: 'Normal',
  })
  const lastTouchedField = useRef<string | null>()

  const getIngredients = async ({
    name = '',
    limit = FETCH_LIMIT,
    offset = 0,
    isModerated = true,
    languageId = 5,
    categoryId = 1,
  }: GetPrivateForSuggestionParams) => {
    const data = await model.Ingredients.getPrivateForSuggestion({
      name,
      limit,
      offset,
      isModerated,
      languageId,
      categoryId,
    })
    setFetchedData(data)
  }

  const getLanguagesForDropdown = async () => {
    try {
      const data = await model.Ingredients.getLanguagesForSuggestedIngredients()
      setFetchedLanguages(data)
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const getListsForDropdown = async () => {
    try {
      const data = await model.Ingredients.getListsForSuggestedIngredients()
      setFetchedLists(data)
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getIngredients({
        name: search,
        isModerated: moderatedFilter.id === 0,
        languageId: selectedLanguage.id,
        categoryId: selectedFilterList.id,
      })
      getLanguagesForDropdown()
      getListsForDropdown()
    }, 250)

    return () => clearTimeout(timer)
  }, [search, moderatedFilter, selectedLanguage, selectedFilterList])

  const handleSetPage = (newPageNumber: number) => {
    if (newPageNumber === page) {
      return
    }
    setPage(newPageNumber)
    getIngredients({
      name: search,
      offset: (newPageNumber - 1) * FETCH_LIMIT,
      isModerated: moderatedFilter.id === 0,
    })
  }

  const handleSelectChange = (item: Lookup) => {
    if (item.id === moderatedFilter.id) return
    setModeratedFilter(item)
    setPage(1)
  }

  const handleSelectLanguage = (item: Lookup) => {
    setSelectedLanguage(item)
  }

  const handleSelectFilterList = (item: Lookup) => {
    setSelectedFilterList(item)
  }

  const updateIngredientsList = (ingredientName: string, id: number) => {
    if (!fetchedData || !ingredientName) {
      return
    }

    const index = fetchedData.ingredients.findIndex(
      (ingredient) => ingredient.name === ingredientName
    )

    const ingredient = fetchedData.ingredients[index]

    ingredient.isModerated = true
    ingredient.id = id
    const updatedList = [...fetchedData.ingredients]

    updatedList.splice(index, 1, ingredient)

    setFetchedData({
      ...fetchedData,
      ingredients: updatedList,
    })
  }

  const chooseLanguageForSynonym = (item: Lookup, name: string) => {
    selectedLanguages = { ...selectedLanguages, [name]: item }
  }

  const chooseIngredientForJoin = (item: Lookup, name: string) => {
    ingredientsForJoin = { ...ingredientsForJoin, [name]: item }
  }

  const deleteSuggestedIngredient = async (name: string) => {
    try {
      if (fetchedData) {
        await model.Ingredients.deleteSuggestedIngredient(name)
        setFetchedData({
          ...fetchedData,
          ingredients: fetchedData?.ingredients.filter(
            (ing) => ing.name !== name
          ),
        })
        toast.success('Suggested ingredient deleted!')
      }
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const createNewSynonym = async (name: string) => {
    const language = selectedLanguages?.[name]
    const ingredientToJoin = ingredientsForJoin?.[name]

    if (!ingredientToJoin || !language?.id) {
      toast.error('Language and ingredient must be selected')
      return
    }

    const payload: SynonymToAdd = {
      name,
      create: 'NewTranslation',
      ingredientId: ingredientToJoin.id,
      languageId: language.id,
    }

    try {
      await model.Ingredients.createAliasOrNewIngredient(payload)

      toast.success('Synonym added!')
      queryClient.invalidateQueries(['getIngredientsByName'])
      updateIngredientsList(name, payload.ingredientId)
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const chooseIngredient = (ingredientName: string) => {
    const ingredientIndex = fetchedData?.ingredients.findIndex(
      (ingredient) => ingredient.name === ingredientName
    )

    if (ingredientIndex === undefined || ingredientIndex === -1) return

    const ingredient = fetchedData?.ingredients[ingredientIndex]

    if (!ingredient) return

    setSelectedIngredient(ingredient)
    setNewIngredientModal(true)
  }

  const closeModal = () => {
    setSelectedIngredient(undefined)
    setNewIngredientModal(false)
  }

  const { data: suggestedIngredientData } =
    useGetIngredientDetailsForModalByNameQuery(currentIngredientName)

  const openDetailModal = (name: string) => {
    setShowDetailModal(true)
    setCurrentIngredientName(name)
  }

  const closeDetailModal = () => {
    setShowDetailModal(false)
    setCurrentIngredientName('')
  }

  const createNewIngredient = async (
    selectedCategoryId: number,
    selectedPhoto: number | undefined
  ) => {
    if (!selectedIngredient || !selectedCategoryId) {
      toast.error('Select category first')

      return
    }

    const ingredient: IngredientToAdd = {
      categoryId: selectedCategoryId,
      name: selectedIngredient.name,
      create: 'NewIngredient',
      photoId: selectedPhoto,
    }

    try {
      const response = await model.Ingredients.createAliasOrNewIngredient(
        ingredient
      )
      toast.success('New ingredient created!')
      queryClient.invalidateQueries(['getIngredientsByName'])
      updateIngredientsList(selectedIngredient.name, response.id)
      closeModal()
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const handleDeleteModalConfirm = () => {
    setShowDeleteModal(false)
    if (selectedIngredient?.name) {
      deleteSuggestedIngredient(selectedIngredient?.name)
      setSelectedIngredient(undefined)
    }
  }

  const handleDeleteModalCancel = () => {
    setSelectedIngredient(undefined)
    setShowDeleteModal(false)
  }

  const openAddOfficialIngModal = () => setShowAddOfficialIngModal(true)

  const closeAddOfficialIngModal = () => setShowAddOfficialIngModal(false)

  const addNewOfficialIngredient = async (selectedCategoryId: number) => {
    if (!officialIngredientToAddName) {
      return toast.error('Name and category must be provided')
    }

    try {
      const addNewOfficialIngResponse =
        await model.Ingredients.addNewIngredient(
          officialIngredientToAddName,
          selectedCategoryId
        )
      toast.success('Ingredient successfully added!')

      ingredientsForJoin = {
        ...ingredientsForJoin,
        [String(lastTouchedField.current)]: {
          id: addNewOfficialIngResponse.id,
          name: addNewOfficialIngResponse.name,
        },
      }
      queryClient.invalidateQueries(['getIngredientsByName'])
      setOfficialIngredientToAddName('')
      closeAddOfficialIngModal()
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const noDataRender = (
    element: React.ReactElement<HTMLDivElement>,
    currentSearch: string
  ) => {
    const modalTriggerHandler = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.preventDefault()
      event.stopPropagation()

      if (!currentSearch) {
        toast.error('Input field cannot be empty!')
      } else {
        openAddOfficialIngModal()
        setOfficialIngredientToAddName(currentSearch)
      }
    }

    const noData = (
      <div className={styles.noDataContainer}>
        <h2>No data found.</h2>
        <p>Add new official ingredient?</p>
        <button onMouseDown={modalTriggerHandler}>Add</button>
      </div>
    )

    return cloneElement(element, { ...element.props }, noData)
  }

  const handleEditList = async (
    event: DropDownListChangeEvent,
    dataItem: SuggestedIngredient
  ) => {
    try {
      await model.Ingredients.editSuggestedIngredient({
        categoryId: dataItem.categoryId,
        typeId: dataItem.typeId,
        isModerated: dataItem.isModerated,
        ingredientName: dataItem.name,
        newCategoryId: event.value.id,
      })
      toast.success('Ingredient updated!')
    } catch (error) {
      httpErrorHandler(error)
    } finally {
      getIngredients({
        name: search,
        isModerated: moderatedFilter.id === 0,
        languageId: selectedLanguage.id,
        categoryId: selectedFilterList.id,
      })
    }
  }

  const handleTouch = (dataItemName: string) => {
    lastTouchedField.current = dataItemName
  }

  return (
    <>
      <IngredientDetailModal
        isOpen={showDetailModal}
        closeModal={closeDetailModal}
        name={currentIngredientName}
        data={suggestedIngredientData}
      />
      <SuggestedAddIngredientModal
        isOpen={newIngredientModal}
        closeModal={closeModal}
        createNewIngredient={createNewIngredient}
        selectedIngredient={selectedIngredient && selectedIngredient}
      />
      <OfficialAddIngredientModal
        isOpen={showAddOfficialIngModal}
        search={officialIngredientToAddName}
        closeModal={closeAddOfficialIngModal}
        addNewIngredient={addNewOfficialIngredient}
      />
      <Modal isOpen={showDeleteModal}>
        <div className={styles.deleteModal}>
          <h3
            className={styles.title}
          >{`Are you sure you want to delete "${selectedIngredient?.name}"?`}</h3>

          <div className={styles.buttons}>
            <button onClick={handleDeleteModalConfirm}>Confirm</button>
            <button onClick={handleDeleteModalCancel}>Cancel</button>
          </div>
        </div>
      </Modal>
      <section className={styles.suggestedIngredients}>
        <h2 className={styles.title}>Latest ingredient updates</h2>

        <div className={styles.searchWrapper}>
          <InputSearch
            placeholder="Start typing to search or add new ingredient"
            value={search}
            setValue={setSearch}
            onChange={() => handleSetPage(1)}
          />
        </div>
        <div className={styles.filters}>
          <Dropdown
            selectedItem={selectedLanguage}
            onSelect={handleSelectLanguage}
            items={[...fetchedLanguages, { name: 'Default (All)', id: 0 }]}
            label="Language:"
          />
          <div>
            <Dropdown
              selectedItem={selectedFilterList}
              onSelect={handleSelectFilterList}
              items={fetchedLists}
              label="Lists:"
            />
            <Dropdown
              selectedItem={moderatedFilter}
              onSelect={handleSelectChange}
              items={filters.moderated}
              label="Show:"
            />
          </div>
        </div>
        <div className={styles.grid}>
          <Grid
            data={fetchedData?.ingredients}
            resizable
            total={fetchedData?.total}
            take={FETCH_LIMIT}
            style={{ minHeight: '450px' }}
            className="suggested-ingredients-grid"
          >
            <GridColumn
              field="name"
              headerCell={() => <GridHeaderCell name="SYNONYM NAME" />}
              cell={({ dataItem: { name, id } }) => (
                <GridIngredientName name={name} id={id} />
              )}
            />
            <GridColumn
              width={130}
              headerCell={() => <GridHeaderCell name="IN LIST" />}
              cell={({ dataItem }) => (
                <td className="inline-edit-field inline-dropdown">
                  <DropDownList
                    popupSettings={{ popupClass: 'dropdown-popup' }}
                    value={{ id: dataItem.categoryId, name: dataItem.category }}
                    onChange={(event: DropDownListChangeEvent) =>
                      handleEditList(event, dataItem)
                    }
                    data={fetchedLists}
                    dataItemKey="id"
                    textField="name"
                  />
                </td>
              )}
            />
            <GridColumn
              width={140}
              headerCell={() => <GridHeaderCell name="FOR INGREDIENT" />}
              cell={({ dataItem: { isModerated, name } }) => (
                <td className="inline-autocomplete-field">
                  <IngredientsPickerCombobox
                    disabled={isModerated}
                    name={name}
                    handleItemClick={chooseIngredientForJoin}
                    defaultValue={ingredientsForJoin?.[name]}
                    noDataRender={noDataRender}
                    placeholder="Join to ingredient"
                    handleTouch={handleTouch}
                  />
                </td>
              )}
            />
            <GridColumn
              width={140}
              headerCell={() => <GridHeaderCell name="LANGUAGE" />}
              cell={({ dataItem: { isModerated, name } }) => (
                <td className="inline-autocomplete-field">
                  <LanguagesPickerCombobox
                    disabled={isModerated}
                    name={name}
                    handleItemClick={chooseLanguageForSynonym}
                    defaultValue={selectedLanguages?.[name]}
                  />
                </td>
              )}
            />
            <GridColumn
              width={110}
              headerCell={() => <GridHeaderCell name="OCCURRENCE" />}
              cell={({ dataItem: { count, name, isModerated } }) => (
                <IngredientOccurrences
                  count={count}
                  disabled={isModerated}
                  onClick={() => count > 0 && openDetailModal(name)}
                />
              )}
            />
            <GridColumn
              headerCell={() => <GridHeaderCell name="ACTIONS" />}
              cell={({ dataItem }) => (
                <SuggestedIngredientActions
                  isModerated={dataItem.isModerated}
                  onAddNewIngredientClick={() =>
                    chooseIngredient(dataItem.name)
                  }
                  onNewSynonymClick={() => createNewSynonym(dataItem.name)}
                  onDeleteSuggestedIngredientClick={() => {
                    setSelectedIngredient(dataItem)
                    setShowDeleteModal(true)
                  }}
                />
              )}
              minResizableWidth={188}
            />
          </Grid>
        </div>
        {fetchedData ? (
          <Pagination
            onSetPage={handleSetPage}
            currentPage={page}
            totalCount={fetchedData.total}
            pageSize={FETCH_LIMIT}
          />
        ) : null}
      </section>
    </>
  )
}

export default SuggestedIngredients
