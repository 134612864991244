import { BsTrash } from 'react-icons/bs'
import { Tooltip } from '@progress/kendo-react-tooltip'

export default function DeleteCell({
  onDeleteClick,
  id,
  isDisabled,
}: {
  id: number | string
  onDeleteClick: (id: number | string) => void
  isDisabled: boolean
}) {
  return (
    <td>
      <div className="oi-cell-container">
        <Tooltip
          key={id}
          content={(props) => <p>{props.title}</p>}
          anchorElement="target"
          position="top"
        >
          <button
            className="delete-btn pointer"
            title="You can't delete this translation if it has synonyms defined!"
            style={{
              display: 'flex',
              marginLeft: 6,
              cursor: 'pointer',
              color: isDisabled ? 'rgba(0,0,0,0.1)' : undefined,
            }}
            onClick={isDisabled ? undefined : () => onDeleteClick(id)}
          >
            <BsTrash
              className="icon"
              size="12px"
              color={isDisabled ? 'rgba(0,0,0,0.5)' : '#FF516D'}
              style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
            />
          </button>
        </Tooltip>
      </div>
    </td>
  )
}
