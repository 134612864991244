import { FC } from 'react'

import { IngredientRecipeDetail } from '../../../../types'
import RecipeTab from '../RecipeTab'
import { DetailPeekTabs, cookingTimeCalc } from '../DetailPeek'

import styles from './OverviewTab.module.scss'

type DetailPeekTypes = {
  displayData: IngredientRecipeDetail
  currentTab: DetailPeekTabs
}

const OverviewTab: FC<DetailPeekTypes> = ({
  displayData: {
    dietTypes,
    cookingTimeInMinutes,
    language,
    originalRecipeFrom,
    waitingTime,
    prepareBeforeTime,
    inspiredBy,
    cookingTools,
    sections,
  },
  currentTab,
}) => {
  const conditionalRenderData = (
    inputValue: string | number | null
  ): string | number => (inputValue ? inputValue : '')

  return currentTab === DetailPeekTabs.Overview ? (
    <div className={styles.detailPeekInfoTableContainer}>
      <div className={styles.foodType}>
        <p>Food type</p>
        <span className={styles.foodTypeList}>
          {dietTypes.map((item, index) => (
            <div key={index} className={styles.foodTypeName}>
              {item.name}
            </div>
          ))}
        </span>
      </div>

      <div className={styles.inspiredBy}>
        <p>Inspired by</p>
        <span>{conditionalRenderData(inspiredBy)}</span>
      </div>

      <div className={styles.cookingTools}>
        <p>Cooking tools</p>
        <span>{conditionalRenderData(cookingTools)}</span>
      </div>

      <div className={styles.cookingTime}>
        <p>Cooking time</p>
        <span>{cookingTimeCalc(cookingTimeInMinutes)}</span>
      </div>
      <div className={styles.prepareBeforeTime}>
        <p>- Prepare before time</p>
        <span>{conditionalRenderData(prepareBeforeTime)}</span>
      </div>

      <div className={styles.agingTime}>
        <p>- Aging time</p>
        <span>{conditionalRenderData(waitingTime)}</span>
      </div>
      <div className={styles.recipeWrittenIn}>
        <p>Recipe written in</p>
        <span>{language.name}</span>
      </div>
      <div className={styles.originalRecipeFrom}>
        <p>Original recipe from</p>
        <span>{conditionalRenderData(originalRecipeFrom)}</span>
      </div>
    </div>
  ) : (
    <RecipeTab sections={sections} />
  )
}

export default OverviewTab
