import { useQuery } from '@tanstack/react-query'
import { model } from '../../../api/model'

const FETCH_LIMIT = 10
const fiveMinutes = 1000 * 60 * 5
const staleTime = fiveMinutes

export const useGetIngredientsByNameQuery = (
  name = '',
  offset = 0,
  limit: number = FETCH_LIMIT,
  categories: number[] = [],
  sortOption: string,
  isDisabled = false,
  showOnlyIngredientsWithoutPhoto = false
) => {
  return useQuery({
    queryKey: [
      isDisabled ? 'getIngredientsByName-Admin' : 'getIngredientsByName',
      {
        name,
        offset,
        limit,
        categories,
        sortOption,
        isDisabled,
        showOnlyIngredientsWithoutPhoto,
      },
    ],
    queryFn: () =>
      model.Ingredients.getIngredientsByName({
        name,
        offset,
        limit,
        categories,
        sortOption,
        isDisabled,
        showIngredientsWithoutPhoto: showOnlyIngredientsWithoutPhoto,
      }),
    staleTime: staleTime,
    keepPreviousData: true,
  })
}
