import Header from '../Header'

import styles from './Page.module.scss'

const Page = ({ children }) => {
  return (
    <div className={styles.page}>
      <Header />
      <main>
        <div className={styles.content}>{children}</div>
      </main>
    </div>
  )
}

export default Page
