export type User = {
  id: number
  userName: string
  email: string
  token: string
  refreshToken: string
}

export enum UserRoles {
  ADMIN = 'Administrators',
  MEMBER = 'Member',
  EDITOR = 'Editor',
  LCT = 'Lct',
  NARRATOR = 'Narrator',
}
