import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useSearchParams } from 'react-router-dom'
import cn from 'clsx'

import { model } from '../../api/model'
import { httpErrorHandler } from '../../api/HttpError'
import Page from '../../components/Page'

import styles from './EmailVerification.module.scss'

type VirificationStatus = 'successful' | 'failed'

const EmailVerification = () => {
  const [searchParams] = useSearchParams()
  const [status, setStatus] = useState<VirificationStatus>()

  const verifyEmail = useCallback(async () => {
    const id = searchParams.get('userId')
    const token = searchParams.get('token')

    try {
      if (!id || !token) return
      await model.Auth.verifyEmail(id, token)
      toast.success('Email verified successfully')
      setStatus('successful')
    } catch (error) {
      httpErrorHandler(error)
      setStatus('failed')
    }
  }, [searchParams])

  useEffect(() => {
    verifyEmail()
  }, [verifyEmail])

  return (
    <Page>
      <div className={styles.emailVerification}>
        <Link className={styles.goHomeLink} to="/">
          Go home
        </Link>
        {status ? (
          <p className={cn([styles.status, styles[status]])}>
            {`Email verification ${status}.`}
          </p>
        ) : null}
      </div>
    </Page>
  )
}

export default EmailVerification
