import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { FC, useState } from 'react'

import { IngredientRecipeDetail } from '../../../types'
import Config from '../../../config/config'
import OverviewTab from './OverviewTab/OverviewTab'

import styles from './DetailPeek.module.scss'

const {
  photoPaths: { recipePhoto, placeholder },
} = Config

export enum DetailPeekTabs {
  Overview = 'Overview',
  Recipe = 'Recipe',
}

type DetailPeekTypes = {
  displayData: IngredientRecipeDetail
  currentTab: DetailPeekTabs
}

dayjs.extend(relativeTime)

export const cookingTimeCalc = (totalMin: number) => {
  if (!totalMin) {
    return ''
  }
  const hours = Math.floor(totalMin / 60)
  const minutes = totalMin % 60
  return `${hours > 0 ? `${hours} h` : ''} ${
    minutes > 0 ? `${minutes} min` : ''
  }`
}

const DetailPeek: FC<DetailPeekTypes> = ({
  displayData: {
    name: recipeName,
    authorName,
    cookingTimeInMinutes,
    country,
    language,
    numberOfResultingPortions,
    image,
    dietTypes,
    sections,
    createdOnDate,
  },
}) => {
  const [currentTab, setCurrentTab] = useState<DetailPeekTypes['currentTab']>(
    DetailPeekTabs.Overview
  )

  const handlePeekTabChange = (value: DetailPeekTypes['currentTab']) =>
    setCurrentTab(value)

  return (
    <div className={styles.detailPeekContainer}>
      <div className={styles.ingredientImageAndInfoContainer}>
        <img
          className={styles.ingredientOverviewImage}
          alt={recipeName}
          src={image ? `${recipePhoto}/${image}` : placeholder}
          onError={(event: any) => {
            event.target.src = placeholder
          }}
        />

        <div className={styles.ingredientOverviewInfo}>
          <div className={styles.titleAndCookingDays}>
            <h3 className={styles.title}>{recipeName}</h3>
            <span className={styles.daysCounter}>
              {dayjs().to(dayjs(createdOnDate))}
            </span>
          </div>

          <div className={styles.authorInfoContainer}>
            <p>{authorName}</p>
            <span>{country}</span>
          </div>

          <div className={styles.timeAndServings}>
            {cookingTimeInMinutes !== undefined ? (
              <div className={styles.time}>
                <p>
                  {cookingTimeInMinutes !== 0 &&
                    cookingTimeCalc(cookingTimeInMinutes)}
                </p>
                <span>Cooking time</span>
              </div>
            ) : null}
            {numberOfResultingPortions !== undefined ? (
              <div className={styles.servings}>
                <p>{numberOfResultingPortions}</p>
                <span>Servings</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.detailPeekTabController}>
        <button
          value={DetailPeekTabs.Overview}
          onClick={() => handlePeekTabChange(DetailPeekTabs.Overview)}
          className={
            currentTab === DetailPeekTabs.Overview ? styles.activeTab : ''
          }
        >
          Overview
        </button>
        <button
          value={DetailPeekTabs.Recipe}
          onClick={() => handlePeekTabChange(DetailPeekTabs.Recipe)}
          className={
            currentTab === DetailPeekTabs.Recipe ? styles.activeTab : ''
          }
        >
          Recipe
        </button>
      </div>
      <OverviewTab
        currentTab={currentTab}
        displayData={
          {
            language,
            cookingTimeInMinutes,
            dietTypes,
            sections,
          } as IngredientRecipeDetail
        }
      />
    </div>
  )
}

export default DetailPeek
