import styles from './CustomLoadingComponent.module.scss'

const CustomLoadingComponent = () => {
  return (
    <div className={styles.loader__wrapper}>
      <div className={styles.loader}>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
      </div>
    </div>
  )
}

export default CustomLoadingComponent
