import { useState } from 'react'

export const useLocalStorage = (keyName: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyName)
      if (value) {
        return JSON.parse(value)
      }
    } catch (err) {
      return `Local storage error with key name ${keyName}`
    }
  })

  const setValue = (newValue) => {
    try {
      localStorage.setItem(keyName, JSON.stringify(newValue))
    } catch (err) {}
    setStoredValue(newValue)
  }
  return [storedValue, setValue]
}
