import { AxiosResponse } from 'axios'
import { User } from '../../../types'
import API from '../../http'

class UserProvider {
  public async login(loginCredential: string, password: string): Promise<User> {
    const response = await API.post<
      {
        loginCredential: string
        password: string
      },
      AxiosResponse<User>
    >('users/login', {
      loginCredential,
      password,
    })

    return response?.data
  }

  public async verifyEmail(userId: string, token: string): Promise<any> {
    await API.post<{
      userId: string
      token: string
    }>('users/confirm-email', {
      userId,
      token,
    })
  }
}

export default UserProvider
