import { Link, useLocation } from 'react-router-dom'

export default function GridIngredientName({
  name,
  id,
}: {
  name: string
  id?: number
}) {
  const currentLocation = useLocation()
  return (
    <td className="oi-cell">
      {id ? (
        <Link
          className="pointer"
          to={`/ingredients/official`}
          state={{ ingredientId: id, fromRoute: currentLocation.pathname }}
        >
          {name}
        </Link>
      ) : (
        <p>{name}</p>
      )}
    </td>
  )
}
