import { FC, useRef, useState } from 'react'
import { GridCellProps } from '@progress/kendo-react-grid'
import { TextArea, TextAreaHandle } from '@progress/kendo-react-inputs'

import { model } from '../../api/model'
import { LocalizationItem } from '../../types'

import styles from './KendoCell.module.scss'

type Column = 'name' | 'key' | 'englishName' | 'category'

interface IProps extends GridCellProps {
  column: Column
  dataItem: LocalizationItem
  languageId?: number
}

const KendoCell: FC<IProps> = ({ dataItem, column, languageId }) => {
  const [initialValue, setInitialValue] = useState<string>(
    dataItem[column] || ''
  )
  const [value, setValue] = useState<string>(dataItem[column] || '')
  const inputRef = useRef<TextAreaHandle>(null)

  const cellStyle = {
    fontWeight: 500,
    color: 'black',
  }

  const updateCell = async () => {
    if (!languageId) {
      return
    }

    if (initialValue !== value.trim()) {
      await model.Localization.updateTranslationItem(
        { ...dataItem, name: value },
        languageId
      )
    }
  }

  return (
    <td style={cellStyle} className={styles.cell}>
      {column === 'name' ? (
        <TextArea
          onChange={(e) => setValue(e.value)}
          ref={inputRef}
          value={value}
          onBlur={updateCell}
          onFocus={(e) =>
            setInitialValue(e.target.element.current?.value ?? '')
          }
          placeholder="type translation"
          autoSize
        />
      ) : (
        dataItem[column]
      )}
    </td>
  )
}

export default KendoCell
