import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { Checkbox } from '@progress/kendo-react-inputs'
import { MultiSelectTree } from '@progress/kendo-react-dropdowns'
import { Splitter, SplitterPaneProps } from '@progress/kendo-react-layout'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'

import { useGetIngredientDetailsForModalQuery } from '../../../components/IngredientDetailModal/DetailPeek/useGetDetailPeekIngredientsData'
import { model } from '../../../api/model'
import InputSearch from '../../../components/InputSearch'
import { Lookup } from '../../../types'
import Pagination from '../../../components/Pagination'
import { httpErrorHandler } from '../../../api/HttpError'
import {
  GridCategories,
  GridHeaderCell,
  OfficialIngredientImage,
} from '../../../components/KendoGridElements'
import OfficialAddIngredientModal from '../../../components/AddNewIngredientModal/OfficialIngredientsModal'
import IngredientOccurrences from '../../../components/KendoGridElements/IngredientOccurrences'
import { useGetIngredientsByNameQuery } from './useGetIngredientsByName'
import { useMultiSelectTree, fields } from '../../../hooks/useMultiSelectTree'
import { getIdsArrayFromLookup } from '../../../helpers/utils'
import ModalImage from '../../../components/ModalImage'
import Config from '../../../config/config'
import IngredientDetailModal from '../../../components/IngredientDetailModal'
import IngredientEdit from './IngredientEdit'
import Dropdown from '../../../components/Dropdown'
import { rowRender } from '../../../components/KendoGridElements/rowRender'
import { useTagData } from './useTagData'
import { Tag } from './Tag'

import styles from './OfficialIngredients.module.scss'

const {
  photoPaths: { officialIngredient },
} = Config

const FETCH_LIMIT = 10

const sortOptionsList: Lookup[] = [
  { id: 1, name: 'Last update' },
  { id: 2, name: 'Alphabetical' },
  { id: 3, name: 'Most popular' },
]

type LocationStateType = {
  ingredientId: number
  fromRoute: string
}

const OfficialIngredients: FC = () => {
  const outlet = useOutlet()

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const [showModal, setShowModal] = useState(false)
  const [showOnlyIngredientsWithoutPhoto, setShowOnlyIngredientsWithoutPhoto] =
    useState(false)
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const [modalImage, setModalImage] = useState('')
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [currentIngredientId, setCurrentIngredientId] = useState<number>()
  const [sidepanelIngredientId, setSidepanelIngredientId] = useState<number>()
  const [sort, setSort] = useState(sortOptionsList[0])

  const gridPanel: SplitterPaneProps = useMemo(() => {
    return {
      size: '100%',
      collapsible: false,
      scrollable: true,
      resizable: false,
    }
  }, [])

  const [panes, setPanes] = useState<SplitterPaneProps[]>([])

  const handleClosingSidePanel = useCallback(() => {
    setPanes([gridPanel, {}])
    setSidepanelIngredientId(undefined)
  }, [gridPanel])

  useEffect(() => {
    return () => handleClosingSidePanel()
  }, [handleClosingSidePanel])

  const openEditScreenPane = () => setPanes([{ ...gridPanel, size: '60%' }])

  const queryClient = useQueryClient()

  const {
    treeData,
    selectedIngredientTypes,
    setSelectedIngredientTypes,
    onChange,
    onExpandChange,
    onFilterChange,
  } = useMultiSelectTree()

  const tagData = useTagData(selectedIngredientTypes)

  const prepareSortString = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')
  }

  const queryKey = useMemo(() => {
    return [
      'getIngredientsByName',
      {
        name: search,
        offset: (page - 1) * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        categories: getIdsArrayFromLookup(selectedIngredientTypes),
        sortOption: prepareSortString(sort.name),
        isDisabled: false,
        showOnlyIngredientsWithoutPhoto: showOnlyIngredientsWithoutPhoto,
      },
    ]
  }, [
    page,
    search,
    selectedIngredientTypes,
    showOnlyIngredientsWithoutPhoto,
    sort.name,
  ])

  const location = useLocation()
  const navigate = useNavigate()

  const [locationState, setLocationState] = useState<LocationStateType | null>(
    (location.state as LocationStateType) || null
  )

  useEffect(() => {
    navigate('.', { replace: true })

    if (locationState) {
      setSidepanelIngredientId(locationState.ingredientId)

      setPanes([{ ...gridPanel, size: '60%' }])
    }
  }, [locationState, gridPanel, navigate])

  const handleRefetchOnComponentMount = useCallback(() => {
    if (location.pathname === '/ingredients/official') {
      queryClient.invalidateQueries(queryKey)
    }
  }, [location.pathname, queryClient, queryKey])

  useEffect(() => {
    handleRefetchOnComponentMount()
  }, [handleRefetchOnComponentMount])

  const { data, isLoading, isError } = useGetIngredientsByNameQuery(
    search,
    (page - 1) * FETCH_LIMIT,
    FETCH_LIMIT,
    getIdsArrayFromLookup(selectedIngredientTypes),
    prepareSortString(sort.name),
    false,
    showOnlyIngredientsWithoutPhoto
  )

  const { data: ingredientDetailsForModal } =
    useGetIngredientDetailsForModalQuery(currentIngredientId as number)

  const getIngredientName = (name: string) => {
    if (locationState && locationState.fromRoute === '/ingredients/suggested') {
      setSearch(name)
      setLocationState(null)
    }
  }

  if (isLoading || isError) {
    return <></>
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeDetailModal = () => {
    setShowDetailModal(false)
  }

  const openModal = async () => {
    if (search.length < 2)
      return toast.error('Minimum ingredient length is 2 characters')

    setShowModal(true)
  }

  const openDetailModal = (id: number) => {
    setShowDetailModal(true)
    setCurrentIngredientId(id)
  }

  const addNewIngredient = async (selectedCategoryId: number) => {
    if (!search) {
      return toast.error('Name and category must be provided')
    }

    try {
      await model.Ingredients.addNewIngredient(search, selectedCategoryId)
      toast.success('Ingredient successfully added!')
      queryClient.invalidateQueries(['getIngredientsByName'])
      setSearch('')
      closeModal()
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const handleSetPage = (newPageNumber: number) => {
    if (newPageNumber === page) {
      return
    }
    setPage(newPageNumber)
  }

  const handleShowOnlyIngredientsWithoutPhoto = (checked: boolean) => {
    handleSetPage(1)
    setShowOnlyIngredientsWithoutPhoto(checked)
  }

  const handleImageClick = (image: string) => {
    if (image) {
      setModalImage(image)
      setShowPhotoModal(true)
    }
  }

  const handleModalClose = () => {
    setModalImage('')
    setShowPhotoModal(false)
  }

  const handleSelectSortOption = (item: Lookup) => {
    setSort(item)
  }

  return (
    <section className={styles.officialIngredients}>
      {!outlet ? (
        <>
          <Splitter panes={panes} orientation={'horizontal'}>
            <div className="pane-content">
              <IngredientDetailModal
                isOpen={showDetailModal}
                closeModal={closeDetailModal}
                id={currentIngredientId}
                data={ingredientDetailsForModal}
              />
              <OfficialAddIngredientModal
                isOpen={showModal}
                search={search}
                closeModal={closeModal}
                addNewIngredient={addNewIngredient}
              />
              <ModalImage isOpen={showPhotoModal} onClick={handleModalClose}>
                <img
                  className={styles.modalImage}
                  alt="Ingredient"
                  src={`${officialIngredient}/${modalImage}`}
                />
              </ModalImage>
              <h2 className={styles.title}>Latest ingredient updates</h2>
              <div className={styles.actions}>
                <div>
                  <InputSearch
                    placeholder="Start typing to search or add new ingredient"
                    value={search}
                    setValue={setSearch}
                    onChange={() => handleSetPage(1)}
                  />
                </div>
                <button onClick={openModal}>+ Add New ingredient</button>
              </div>
              <div className={styles.filters}>
                <div>
                  <div className="flex multiselect-official-filter">
                    <MultiSelectTree
                      label="Category:"
                      className="multiselect-tree"
                      popupSettings={{ popupClass: 'multiselect-popup' }}
                      size="small"
                      fillMode="flat"
                      placeholder="Select ingredient categories..."
                      data={treeData}
                      value={selectedIngredientTypes}
                      tags={tagData}
                      tag={(props) => (
                        <Tag
                          {...props}
                          selectedIngredientTypes={selectedIngredientTypes}
                          setSelectedIngredientTypes={
                            setSelectedIngredientTypes
                          }
                        />
                      )}
                      dataItemKey={fields.dataItemKey}
                      textField={fields.textField}
                      subItemsField={fields.subItemsField}
                      checkField={fields.checkField}
                      expandField={fields.expandField}
                      checkIndeterminateField={fields.checkIndeterminateField}
                      onChange={onChange}
                      onExpandChange={onExpandChange}
                      filterable={true}
                      onFilterChange={onFilterChange}
                    />
                  </div>

                  <div>
                    <Checkbox
                      label="Without photo"
                      onChange={(e) =>
                        handleShowOnlyIngredientsWithoutPhoto(e.value)
                      }
                    />
                  </div>
                </div>
                <Dropdown
                  selectedItem={sort}
                  onSelect={handleSelectSortOption}
                  items={sortOptionsList}
                  label="Sort by:"
                />
              </div>

              <div className={styles.grid}>
                <Grid
                  data={data.ingredients}
                  resizable
                  total={data.total}
                  take={FETCH_LIMIT}
                  style={{ minHeight: '450px' }}
                  className="official-ingredients-grid"
                  rowRender={(row, props) => {
                    return rowRender(row, {
                      ...props,
                      isSelected: props.dataItem.id === sidepanelIngredientId,
                    })
                  }}
                >
                  <GridColumn
                    field="image"
                    width={80}
                    headerCell={() => <GridHeaderCell name="IMAGE" />}
                    cell={({ dataItem }) => (
                      <OfficialIngredientImage
                        image={dataItem.image}
                        onClick={() => handleImageClick(dataItem.image)}
                        newSuggestions={dataItem.updates}
                      />
                    )}
                  />
                  <GridColumn
                    field="name"
                    headerCell={() => <GridHeaderCell name="INGREDIENT NAME" />}
                    cell={({ dataItem: { name, id } }) => (
                      <td className="oi-cell">
                        <div
                          onClick={() => {
                            setSidepanelIngredientId(id)
                            openEditScreenPane()
                          }}
                        >
                          <p className="pointer">{name}</p>
                        </div>
                      </td>
                    )}
                  />
                  <GridColumn
                    field="creator.userName"
                    width={100}
                    headerCell={() => <GridHeaderCell name="AUTHOR" />}
                    className="oi-cell-orange"
                  />
                  <GridColumn
                    headerCell={() => <GridHeaderCell name="CATEGORY" />}
                    className="oi-cell-orange"
                    cell={({ dataItem: { categories } }) => (
                      <GridCategories categories={categories} />
                    )}
                    width={150}
                  />
                  <GridColumn
                    headerCell={() => <GridHeaderCell name="OCCURRENCE" />}
                    cell={({ dataItem: { numberOfTimesUsed, id } }) => (
                      <IngredientOccurrences
                        count={numberOfTimesUsed}
                        onClick={() =>
                          numberOfTimesUsed > 0 && openDetailModal(id)
                        }
                      />
                    )}
                    width={130}
                  />
                </Grid>
                {data.ingredients ? (
                  <Pagination
                    onSetPage={handleSetPage}
                    currentPage={page}
                    totalCount={data.total}
                    pageSize={FETCH_LIMIT}
                  />
                ) : null}
              </div>
            </div>
            <div className="pane-content">
              {sidepanelIngredientId !== undefined && (
                <IngredientEdit
                  ingredientId={sidepanelIngredientId}
                  handleClosingSidePanel={handleClosingSidePanel}
                  getIngredientName={getIngredientName}
                />
              )}
            </div>
          </Splitter>
        </>
      ) : (
        <Outlet />
      )}
    </section>
  )
}

export default OfficialIngredients
