import { FC, useState } from 'react'
import { toast } from 'react-hot-toast'

import Modal from '../Modal'
import RoundedThemeButton from '../RoundedThemeButton'
import { Lookup } from '../../types'
import {
  IngredientsPickerCombobox,
  LanguagesPickerCombobox,
} from '../KendoGridElements'
import { model } from '../../api/model'
import { httpErrorHandler } from '../../api/HttpError'

import styles from './MergeIngredientModal.module.scss'

interface Props {
  isOpen: boolean
  closeModal: () => void
  currentIngredient: Lookup
  ingredientForMerge: Lookup
  handleAfterMerge: () => void
}

export type MergeIngredientsData = {
  ingredientId: number
  mergeWithIngredientId: number
  languageId: number
}

const MergeIngredientModal: FC<Props> = ({
  isOpen,
  closeModal,
  currentIngredient,
  ingredientForMerge,
  handleAfterMerge,
}) => {
  const [ingredientForMerging, setIngredientForMerging] =
    useState(ingredientForMerge)
  const [languageForMerging, setLanguageForMerging] = useState<Lookup>()

  const chooseLanguageForMerging = (language: Lookup) => {
    setLanguageForMerging(language)
  }

  const handleMergeSubmit = async () => {
    if (languageForMerging && ingredientForMerging && currentIngredient) {
      if (currentIngredient.id === ingredientForMerging.id) {
        toast.error('You cannot merge ingredient with itself!')
      } else {
        try {
          const payload: MergeIngredientsData = {
            ingredientId: currentIngredient.id,
            mergeWithIngredientId: ingredientForMerging.id,
            languageId: languageForMerging.id,
          }

          await model.Ingredients.mergeOfficialIngredients(payload)

          closeModal()
        } catch (error) {
          httpErrorHandler(error)
        } finally {
          handleAfterMerge()
        }
      }
    } else {
      toast.error('Please fill all fields!')
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.modal}>
        <h3 className={styles.title}>Merging ingredients</h3>
        <div className={styles.content}>
          <div className={styles.section}>
            <p className={styles.label}>Merging</p>
            <div className={styles.autocomplete}>
              <IngredientsPickerCombobox
                name={''}
                handleItemClick={(item: Lookup) =>
                  setIngredientForMerging(item)
                }
                defaultValue={ingredientForMerging}
                placeholder="Type ingredient"
              />
            </div>
          </div>

          <div className={styles.section}>
            <p className={styles.label}>Merging on</p>
            <div className={styles.name}>{currentIngredient.name}</div>
          </div>

          <div className={styles.section}>
            <p className={styles.label}>For language</p>
            <div className={styles.autocomplete}>
              <LanguagesPickerCombobox
                name={''}
                handleItemClick={chooseLanguageForMerging}
                defaultValue={{ id: 0, name: '' }}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <RoundedThemeButton onClick={() => closeModal()}>
            Cancel
          </RoundedThemeButton>
          <RoundedThemeButton primary onClick={handleMergeSubmit}>
            Merge ingredient
          </RoundedThemeButton>
        </div>
      </div>
    </Modal>
  )
}

export default MergeIngredientModal
