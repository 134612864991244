import { FC } from 'react'
import { VscChromeClose as CloseIcon } from 'react-icons/vsc'

import Config from '../../../../../config/config'
import { Image } from '../../../../../types'
import { UPLOADED_IMAGE_ID } from '../../../../../hooks/useOfficialIngredientImage'

import styles from './GridImage.module.scss'

const {
  photoPaths: { officialIngredient, placeholder },
} = Config

interface IProps {
  image: Image
  checked: boolean
  select: (id: number | string) => void
  deleteImage: (id: number | string) => void
}

const GridImage: FC<IProps> = ({
  image: { id, image, author },
  checked,
  select,
  deleteImage,
}) => {
  const getImagePath = () => {
    if (id === UPLOADED_IMAGE_ID) {
      return image
    } else if (image?.length) {
      return `${officialIngredient}/${image}`
    } else {
      return placeholder
    }
  }

  return (
    <div className={styles.gridImage}>
      <img
        className={styles.image}
        src={getImagePath()}
        alt="Suggested"
        onError={(event: any) => {
          event.target.src = placeholder
        }}
      />
      <button onClick={() => deleteImage(id)} className={styles.deleteBtn}>
        <CloseIcon />
      </button>
      <div className={styles.info}>
        <input type="checkbox" checked={checked} onChange={() => select(id)} />
        <p>@{author}</p>
      </div>
    </div>
  )
}

export default GridImage
