import { atom } from 'recoil'
import {
  IngredientTranslationToAdd,
  IngredientTranslationToDelete,
  IngredientTranslationToEdit,
  Ingredient,
} from '../types'

export const ingredientTranslation = atom({
  key: 'ingredientTranslation',
  default: {} as Ingredient,
})

export const translationsToAdd = atom({
  key: 'translationsToAdd',
  default: [] as IngredientTranslationToAdd[],
})

export const translationsToEdit = atom({
  key: 'translationsToEdit',
  default: [] as IngredientTranslationToEdit[],
})

export const translationsToDelete = atom({
  key: 'translationsToDelete',
  default: [] as IngredientTranslationToDelete[],
})
