import { FC, ReactNode, useEffect } from 'react'
import cn from 'clsx'

import styles from './Modal.module.scss'

interface Props {
  children: ReactNode
  isOpen: boolean
  isDetail?: boolean
}

const Modal: FC<Props> = ({ children, isOpen, isDetail }) => {
  useEffect(() => {
    if (isOpen) {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden'
      }
    } else {
      document.body.style.overflow = 'scroll'
    }
  }, [isOpen])

  return (
    <div className={cn(styles.modal, { [styles.closed]: !isOpen })}>
      <div className={isDetail ? styles.detailModal : styles.centered}>
        <div className={styles.modalContent}>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Modal
