const env = process.env.TARGET_ENV
const isProd = env === 'production'

const azureCDN = isProd
  ? 'https://lct.azureedge.net'
  : 'https://lctdev.azureedge.net'

const photosAzurePath = `${azureCDN}/photos/${isProd ? 'production' : `${env}`}`

const Config = {
  photoPaths: {
    placeholder: 'https://i.stack.imgur.com/y9DpT.jpg',
    officialIngredient: `${photosAzurePath}/ingredientphotos`,
    privateIngredient: `${photosAzurePath}/privateingredients`,
    recipePhoto: `${photosAzurePath}/recipephotos`,
  },
}

export default Config
