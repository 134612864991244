import { useMemo, useContext, createContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import toast from 'react-hot-toast'

import { model } from '../api/model'
import { User, UserRoles } from '../types'
import { useLocalStorage } from './useLocalStorage'
import { httpErrorHandler } from '../api/HttpError'

interface IAuth {
  user: User
  isAdmin: boolean
  isLCTRole: boolean
  isEditor: boolean
  login: (credential: string, password: string) => Promise<void>
  logout: () => void
}

const AuthContext = createContext<IAuth | undefined>(undefined)

export default function useAuth(): IAuth {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

const decodeTokenFromBackend = (decodedObj: JwtPayload) => {
  let isAdmin = false
  let isEditor = false
  let isLCTRole = false

  Object.keys(decodedObj).forEach(function (key) {
    const res = key.split('/')
    if (res.length > 1) {
      if (res[res.length - 1] === 'role') {
        const roles: UserRoles[] = decodedObj[key]
        if (roles.includes(UserRoles.ADMIN)) {
          isAdmin = true
        }
        if (roles.includes(UserRoles.EDITOR)) {
          isEditor = true
        }
        if (roles.includes(UserRoles.LCT)) {
          isLCTRole = true
        }
      }
    }
  })

  return {
    isAdmin,
    isEditor,
    isLCTRole,
  }
}

export function AuthProvider({ children }) {
  const [user, setUser] = useLocalStorage('@user')
  const navigate = useNavigate()

  let admin = false
  let lct = false
  let editor = false

  if (user?.token) {
    const decodedToken: JwtPayload = jwtDecode(user.token)
    const { isAdmin, isEditor, isLCTRole } =
      decodeTokenFromBackend(decodedToken)

    admin = isAdmin
    lct = isLCTRole
    editor = isEditor
  }

  const login = useCallback(
    async (credential: string, password: string) => {
      try {
        const loggedUser = await model.Auth.login(credential, password)
        if (loggedUser) {
          localStorage.setItem('@user', JSON.stringify(loggedUser))
          setUser(loggedUser)
          navigate('/ingredients', { replace: true })
        }
        toast.success('Welcome back!')
      } catch (error) {
        httpErrorHandler(error)
      }
    },
    [navigate, setUser]
  )

  const logout = useCallback(() => {
    setUser(null)
    localStorage.clear()
    navigate('/login', { replace: true })
  }, [navigate, setUser])

  const value = useMemo(
    () => ({
      user,
      isAdmin: admin,
      isEditor: editor,
      isLCTRole: lct,
      login,
      logout,
    }),
    [admin, editor, lct, login, logout, user]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
