import { useCallback, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import { resizeToBigFile, resizeToSmallFile } from '../helpers/imageResizer'
import { ingredientTranslation } from '../atoms/IngredientTranslationAtom'
import { httpErrorHandler } from '../api/HttpError'
import { model } from '../api/model'
import { Image } from '../types'
import useAuth from './useAuth'

export const UPLOADED_IMAGE_ID = 'uploaded-image'

interface OfficialIngredientHookProps {
  uploadedImage: File | null
  selectedImageId: number | string | undefined
  featuredImage: Image | undefined
  submitedImages: Image[]
  uploadIngredientImage: ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => void
  handleImageCheck: (id: number | string) => void
  handleImageDelete: (id: number | string) => Promise<void>
  resetSelectedImage: () => void
  setImageAsFeatured: () => Promise<void>
}

export const useOfficialIngredientImage = (): OfficialIngredientHookProps => {
  const {
    user: { userName },
  } = useAuth()

  const [data, setData] = useRecoilState(ingredientTranslation)

  const [uploadedImage, setUploadedImage] = useState<File | null>(null)
  const [selectedImageId, setSelectedImageId] = useState<number | string>()

  const { featuredImage, submitedImages } = useMemo(() => {
    const allImages = data?.images
    const featuredImage = allImages?.find((img) => img.approvedByAdmin)
    const submitedImages = allImages?.filter((img) => !img.approvedByAdmin)

    return { featuredImage, submitedImages }
  }, [data?.images])

  const uploadIngredientImage = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files?.[0]) {
      const images = data.images.filter(
        (image) => image.id !== UPLOADED_IMAGE_ID
      )
      const imageCredit = prompt(
        'Give credit to an image author. Leave empty if you are an image author'
      )

      const uploadedImage: Image = {
        id: UPLOADED_IMAGE_ID,
        author: userName,
        image: URL.createObjectURL(files?.[0]),
        approvedByAdmin: false,
        credit: imageCredit,
      }

      setData({ ...data, images: [...images, uploadedImage] })
      setSelectedImageId(UPLOADED_IMAGE_ID)
      setUploadedImage(files?.[0])
    }
  }

  const handleImageCheck = (id: number | string) => {
    setSelectedImageId(selectedImageId !== id ? id : undefined)
  }

  const handleImageDelete = async (id: number | string) => {
    try {
      const images = data.images.filter((image) => image.id !== id)
      setData({ ...data, images })

      if (selectedImageId === id) {
        setSelectedImageId(undefined)
      }

      if (typeof id === 'number') {
        await model.Ingredients.deleteImage(id)
      } else {
        setUploadedImage(null)
      }
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const resetSelectedImage = useCallback(() => {
    setUploadedImage(null)
    setSelectedImageId(undefined)
  }, [])
  const setImageAsFeatured = async () => {
    if (uploadedImage) {
      const [smallImage, largeImage] = await Promise.all([
        resizeToSmallFile(uploadedImage),
        resizeToBigFile(uploadedImage),
      ])

      await model.Ingredients.uploadIngredientImage(
        data.id,
        selectedImageId === UPLOADED_IMAGE_ID,
        smallImage,
        largeImage,
        data.images.find((image) => image.id === UPLOADED_IMAGE_ID)?.credit ||
          null
      )
    }
  }

  return {
    uploadedImage,
    selectedImageId,
    featuredImage,
    submitedImages,
    uploadIngredientImage,
    handleImageCheck,
    handleImageDelete,
    resetSelectedImage,
    setImageAsFeatured,
  }
}
