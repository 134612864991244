import { DropDownTree } from '@progress/kendo-react-dropdowns'

import { FC } from 'react'

import { useDropdownTree, dropdownFields } from '../../../hooks/useDropdownTree'
import RoundedThemeButton from '../../RoundedThemeButton'
import Modal from '../../Modal'

import styles from './OfficialAddIngredientModal.module.scss'

interface Props {
  isOpen: boolean
  search: string
  closeModal: () => void
  addNewIngredient: (selectedCategoryId: number) => Promise<string | undefined>
}

const OfficialAddIngredientModal: FC<Props> = ({
  isOpen,
  search,
  closeModal,
  addNewIngredient,
}) => {
  const {
    treeData,
    selectedDropdownCategory,
    filter,
    onChange,
    onExpandChange,
    onFilterChange,
    setDropdownDataToInitialState,
  } = useDropdownTree()

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.modal}>
        <h3 className={styles.title}>
          Add &quot;{search}&quot; as new ingredient
        </h3>
        <div className={styles.categoryChoose}>
          <p className={styles.label}>Ingredient Category</p>
          <DropDownTree
            style={{ width: '300px' }}
            placeholder="Select ingredient category..."
            data={treeData}
            value={selectedDropdownCategory}
            onChange={onChange}
            fillMode="flat"
            dataItemKey={dropdownFields.dataItemKey}
            textField={dropdownFields.textField}
            subItemsField={dropdownFields.subItemsField}
            expandField={dropdownFields.expandField}
            onExpandChange={onExpandChange}
            filterable={true}
            onFilterChange={onFilterChange}
            filter={filter.value}
          />
        </div>
        <div className={styles.buttons}>
          <RoundedThemeButton
            onClick={() => {
              closeModal()
              setDropdownDataToInitialState()
            }}
          >
            Cancel
          </RoundedThemeButton>
          <RoundedThemeButton
            primary
            onClick={() => {
              if (selectedDropdownCategory) {
                addNewIngredient(selectedDropdownCategory.id)
                setDropdownDataToInitialState()
              }
            }}
            disabled={!selectedDropdownCategory}
          >
            Add new ingredient
          </RoundedThemeButton>
        </div>
      </div>
    </Modal>
  )
}

export default OfficialAddIngredientModal
