import React from 'react'
import Header from '../../components/Header'

import styles from './NotFound.module.scss'

const NotFound: React.FC = () => {
  return (
    <div className={styles.notFound}>
      <Header />
      <div className={styles.container}>
        <p>
          404
          <br />
          Not found!
        </p>
      </div>
    </div>
  )
}

export default NotFound
