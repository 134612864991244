import { useMemo } from 'react'
import { Tooltip } from '@progress/kendo-react-tooltip'

import { IngredientTypes } from '../../types/ingredients/IngredientTypes'

export default function GridIngredientName({
  categories,
}: {
  categories: IngredientTypes[]
}) {
  const formatCategoriesForGrid = useMemo(() => {
    return categories
      .map((category) => {
        return `${category.name}${
          category.subCategories.length > 0
            ? ` (${category.subCategories.length})`
            : ''
        }`
      })
      .join(', ')
  }, [categories])

  const formatCategoriesForTooltip = useMemo(() => {
    return categories
      .map((category) => {
        const subCategoryNames = category.subCategories
          .map((subCategory) => subCategory.name)
          .join(', ')

        if (categories.length === 1) return `${subCategoryNames}`
        else return `${category.name}: ${subCategoryNames}`
      })
      .join('\n')
  }, [categories])

  return (
    <td className="oi-cell">
      <Tooltip
        openDelay={100}
        position="bottom"
        anchorElement="target"
        className="grid-tooltip"
      >
        <p className="category-cell-text" title={formatCategoriesForTooltip}>
          {formatCategoriesForGrid}
        </p>
      </Tooltip>
    </td>
  )
}
