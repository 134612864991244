import { FC, useCallback } from 'react'
import { MultiSelectTreeTagProps } from '@progress/kendo-react-dropdowns'
import { useRecoilState } from 'recoil'
import { RiCloseCircleFill as CloseIcon } from 'react-icons/ri'

import { IngredientTypesValue } from '../../../types/ingredients/IngredientTypes'
import { ingredientTranslation } from '../../../atoms/IngredientTranslationAtom'
import { Lookup } from '../../../types'

import styles from './Tag.module.scss'

const preventDefault = (e: React.MouseEvent<HTMLLIElement>) =>
  e.preventDefault()
const stopPropagation = (e: React.MouseEvent<HTMLLIElement>) =>
  e.stopPropagation()

interface IngredientProps {
  selectedIngredientTypes: IngredientTypesValue[]
  setSelectedIngredientTypes: React.Dispatch<
    React.SetStateAction<IngredientTypesValue[]>
  >
}

export const Tag: FC<MultiSelectTreeTagProps & IngredientProps> = ({
  tagData,
  guid,
  selectedIngredientTypes,
  setSelectedIngredientTypes,
}) => {
  const [data, setData] = useRecoilState(ingredientTranslation)

  const handleDeleteTag = useCallback(() => {
    const subCategoriesOfTagData: Lookup[] = tagData.data[0].subCategories

    const reducedCategories = selectedIngredientTypes.reduce((acc, curr) => {
      if (
        subCategoriesOfTagData.find((itm) => itm.id === curr.id) ||
        tagData.data[0].id === curr.id
      ) {
        return acc
      } else {
        return acc.concat({ ...curr })
      }
    }, [] as IngredientTypesValue[])
    setSelectedIngredientTypes(reducedCategories)
    setData({ ...data, categories: reducedCategories })
  }, [
    data,
    selectedIngredientTypes,
    setData,
    setSelectedIngredientTypes,
    tagData.data,
  ])

  return (
    <li
      className={styles.tagContainer}
      id={`tag-${guid}-${tagData.text.replace(/\s+/g, '-')}`}
      onMouseDown={preventDefault}
      onClick={stopPropagation}
      aria-selected={true}
      role="option"
    >
      <p>{tagData.text}</p>
      <span
        aria-label="delete"
        onClick={handleDeleteTag}
        className={styles.iconContainer}
      >
        <CloseIcon color="white" size="16px" />
      </span>
    </li>
  )
}
