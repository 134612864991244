import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const FreeRoute = () => {
  const { user } = useAuth()

  return user?.token ? <Navigate to="/" replace /> : <Outlet />
}

export default FreeRoute
