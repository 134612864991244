import { FC } from 'react'
import { HiOutlineChevronDown as DropdownIcon } from 'react-icons/hi'
import { AiOutlineCheck as SelectedIcon } from 'react-icons/ai'

import { Lookup } from '../../types'

import styles from './Dropdown.module.scss'

interface Props {
  onSelect: (item: Lookup | any) => void
  items: Lookup[]
  placeholder?: string
  selectedItem?: Lookup
  label?: string
}

const Dropdown: FC<Props> = ({
  items,
  onSelect,
  selectedItem,
  label,
  placeholder,
}) => {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownHeader}>
        <div>
          {label ? <p className={styles.label}>{label}</p> : null}

          <p className={styles.selected}>
            {selectedItem ? selectedItem.name : placeholder}
          </p>
        </div>

        <DropdownIcon className={styles.arrow} />
      </div>

      <div className={styles.dropdown}>
        <div className={styles.dropdownList}>
          {items.map((item) => (
            <div
              className={
                item.id === selectedItem?.id ? styles.selectedItem : styles.item
              }
              key={item.id}
              onClick={() => onSelect(item)}
            >
              {item.name}
              {item.id === selectedItem?.id && (
                <SelectedIcon className={styles.selectedIcon} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dropdown
