import { FC } from 'react'
import cn from 'clsx'

import { DOTS, usePagination } from '../../hooks/usePagination'

import styles from './Pagination.module.scss'

interface Props {
  onSetPage: (newPageNumber: number) => void
  currentPage: number
  totalCount: number
  pageSize: number
  siblingCount?: number
}

const Pagination: FC<Props> = ({
  onSetPage,
  currentPage,
  totalCount,
  pageSize,
  siblingCount = 1,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className={styles.pagination}>
      <div className={styles.wrapper}>
        <button
          className={cn(styles.arrow, {
            [styles.disabled]: currentPage === 1,
          })}
          onClick={() => onSetPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {paginationRange.map((pageNumber, i) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <button key={`${DOTS}-${i}`} className={styles.dots} disabled>
                {DOTS}
              </button>
            )
          }

          if (typeof pageNumber !== 'number') {
            return null
          }

          // Render our Page Pills
          return (
            <button
              key={pageNumber}
              className={cn(styles.pill, {
                [styles.selected]: currentPage === pageNumber,
              })}
              onClick={() => onSetPage(pageNumber)}
            >
              {pageNumber}
            </button>
          )
        })}
        <button
          className={cn(styles.arrow, {
            [styles.disabled]: lastPage === currentPage,
          })}
          onClick={() => onSetPage(currentPage + 1)}
          disabled={lastPage === currentPage}
        >
          &gt;
        </button>
      </div>
    </div>
  )
}

export default Pagination
