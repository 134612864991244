import React, { useState } from 'react'
import { useRecoilState } from 'recoil'

import {
  ingredientTranslation,
  translationsToAdd,
  translationsToDelete,
  translationsToEdit,
} from '../../atoms/IngredientTranslationAtom'
import useAuth from '../../hooks/useAuth'
import { AliasType } from '../../types'

export default function DefaultTranslation({
  id,
  translation,
  aliases,
  languageId,
}: {
  id: number | string
  translation: string
  languageId: number
  aliases: AliasType[]
}) {
  const {
    user: { id: userId, userName },
  } = useAuth()

  const [inputValue, setInputValue] = useState(translation)
  const isDisabled = !languageId

  const [data, setData] = useRecoilState(ingredientTranslation)
  const [toAdd, setToAdd] = useRecoilState(translationsToAdd)
  const [toEdit, setToEdit] = useRecoilState(translationsToEdit)
  const [toDelete, setToDelete] = useRecoilState(translationsToDelete)

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (
      e.target.value.trim() === '' &&
      (!!aliases.length || languageId === 5)
    ) {
      alert(
        !!aliases.length
          ? "You can't delete a default translation if you have synonyms! \nPlease first delete all synonyms and then delete default translation!"
          : "You can't delete English default translation, sorry!"
      )
      setInputValue(translation)
      return
    } else if (e.target.value.trim() === '') {
      const updatedDataTranslations = data.translations.filter(
        (translation) => translation.id !== id
      )

      if (typeof id === 'string') {
        if (toAdd.find((itm) => itm.id === id)) {
          const updatedToAdd = toAdd.filter(
            (translation) => translation.id !== id
          )
          setToAdd(updatedToAdd)
        }
      } else if (typeof id === 'number') {
        if (toEdit.find((itm) => itm.id === id)) {
          const updatedToEdit = toEdit.filter(
            (translation) => translation.id !== id
          )
          setToEdit(updatedToEdit)
        }

        if (!toDelete.find((itm) => itm.id === id)) {
          setToDelete([...toDelete, { id: id }])
        }
      }

      setData({
        ...data,
        translations: updatedDataTranslations,
      })
      return
    }

    if (translation === e.target.value.trim()) {
      return
    }

    const updatedDataTranslations = data.translations.map((translation) =>
      translation.id === id && translation.name !== e.target.value.trim()
        ? {
            ...translation,
            name: e.target.value.trim() as string,
            creator: !translation.creator?.id
              ? {
                  id: userId,
                  userName: userName,
                }
              : { ...translation.creator },
          }
        : translation
    )

    if (typeof id === 'string') {
      if (toAdd.find((itm) => itm.id === id)) {
        const updatedToAdd = toAdd.map((translation) =>
          translation.id === id && translation.name !== e.target.value.trim()
            ? { ...translation, name: e.target.value.trim() as string }
            : translation
        )
        setToAdd(updatedToAdd)
      } else {
        setToAdd([
          ...toAdd,
          {
            id: id,
            name: e.target.value.trim() as string,
            isDefault: true,
            languageId: languageId,
          },
        ])
      }
    } else if (typeof id === 'number') {
      if (toEdit.find((itm) => itm.id === id)) {
        const updatedToEdit = toEdit.map((translation) =>
          translation.id === id && translation.name !== e.target.value.trim()
            ? { ...translation, name: e.target.value.trim() as string }
            : translation
        )
        setToEdit(updatedToEdit)
      } else {
        setToEdit([
          ...toEdit,
          {
            id: id,
            name: e.target.value.trim() as string,
            languageId: languageId,
          },
        ])
      }
    }

    setData({
      ...data,
      name: languageId === 5 ? e.target.value.trim() : data.name,
      translations: updatedDataTranslations,
    })
  }

  return (
    <td className="oi-cell pointer flex inline-edit-field">
      <input
        type="text"
        value={inputValue}
        title="Add language of the translation first!"
        className="grid-input"
        disabled={isDisabled}
        placeholder="Write translation"
        onBlur={isDisabled ? undefined : (e) => handleBlur(e)}
        onChange={isDisabled ? undefined : (e) => setInputValue(e.target.value)}
      />
      {aliases.length >= 1 && <span>{`(${aliases.length})`}</span>}
    </td>
  )
}
