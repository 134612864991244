import { AvailableLanguagesLookup, Lookup } from '../../../types'
import API from '../../http'

class LanguagesProvider {
  public async getLanguagesForLocalisation(): Promise<AvailableLanguagesLookup> {
    const { data } = await API.get('languages/languages-for-localisation')
    return data
  }

  public async getLanguages(): Promise<Lookup[]> {
    const { data } = await API.get('languages/lookup')

    return data
  }
}

export default LanguagesProvider
