import { Navigate, useLocation, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

export interface IProtectedRouteState {
  path: string
}

const ProtectedRoute = () => {
  const { user } = useAuth()
  const location = useLocation()

  return user?.token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  )
}

export default ProtectedRoute
