import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from '@progress/kendo-data-query'
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns'
import { useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { availableLanguages } from '../../atoms/AvailableLanguages'
import {
  ingredientTranslation,
  translationsToAdd,
  translationsToEdit,
} from '../../atoms/IngredientTranslationAtom'

export default function OfficialIngredientLanguage({
  id,
  languageId,
}: {
  id: number | string
  languageId: number
}) {
  const languages = useRecoilValue(availableLanguages)
  const [data, setData] = useRecoilState(ingredientTranslation)
  const [toAdd, setToAdd] = useRecoilState(translationsToAdd)
  const [toEdit, setToEdit] = useRecoilState(translationsToEdit)

  const languagesNotTaken = useMemo(() => {
    const takenLanguages = data.translations.map((trans) => trans.languageId)
    return languages.filter((lang) => !takenLanguages.includes(lang.id))
  }, [data.translations, languages])

  const [langs, setLangs] = useState(languagesNotTaken)

  const defaultLanguageForTranslation = useMemo(() => {
    return languages.find((lang) => lang.id === languageId)
  }, [languageId, languages])

  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    return filterBy(languagesNotTaken, filter)
  }

  const filterChange = (event: {
    filter: FilterDescriptor | CompositeFilterDescriptor
  }) => {
    setLangs(filterData(event.filter))
  }

  const handleChange = (e: ComboBoxChangeEvent) => {
    if (e.value?.id === null || e.value?.id === undefined) {
      return
    }

    if (data.translations.some((trans) => trans.languageId === e.value?.id)) {
      alert(`Transalations for ${e.value?.name} already exist!`)
      return
    }

    const updatedDataTranslations = data.translations.map((translation) =>
      translation.id === id
        ? {
            ...translation,
            languageId: e.value.id as number,
            language: e.value.name as string,
          }
        : translation
    )
    if (typeof id === 'string') {
      const updatedToAdd = toAdd.map((translation) =>
        translation.id === id || translation.languageId === languageId
          ? { ...translation, languageId: e.value.id as number }
          : translation
      )
      setToAdd(updatedToAdd)
    } else if (typeof id === 'number') {
      const updatedToEdit = toEdit.map((translation) =>
        translation.id === id || translation.languageId === languageId
          ? { ...translation, languageId: e.value.id as number }
          : translation
      )
      setToEdit(updatedToEdit)
    }
    setData({ ...data, translations: updatedDataTranslations })
  }

  return (
    <td className="inline-edit-field">
      <ComboBox
        className="comboBox"
        clearButton={false}
        defaultValue={defaultLanguageForTranslation}
        disabled={languageId === 5}
        data={langs}
        textField="name"
        suggest
        placeholder="Select language"
        filterable
        onFilterChange={filterChange}
        onChange={(e) => handleChange(e)}
      />
    </td>
  )
}
