import { FC } from 'react'
import cn from 'clsx'

import styles from './ThemeButton.module.scss'

interface IProps {
  title: string
  onClick: () => void
  isDisabled?: boolean
  style?: React.CSSProperties
}

const ThemeButton: FC<IProps> = ({ title, onClick, isDisabled, style }) => {
  return (
    <button
      className={cn([styles.themeButton])}
      style={style}
      onClick={onClick}
      disabled={isDisabled}
    >
      {title}
    </button>
  )
}

export default ThemeButton
