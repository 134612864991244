import { FC, ReactNode, useEffect } from 'react'
import cn from 'clsx'
import styles from './ModalImage.module.scss'

interface Props {
  children: ReactNode
  isOpen: boolean
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined
}

const ModalImage: FC<Props> = ({ children, isOpen, onClick }) => {
  useEffect(() => {
    if (isOpen) {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden'
      }
    } else {
      document.body.style.overflow = 'scroll'
    }
  }, [isOpen])

  return (
    <div
      onClick={onClick}
      className={cn(styles.modal, { [styles.closed]: !isOpen })}
    >
      <div className={styles.centered}>
        <div className={styles.modalContent}>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ModalImage
