import React, { useCallback, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import ProtectedRoute from './router/ProtectedRoute'
import FreeRoute from './router/FreeRoute'
import Layout from './components/Layout'
import OfficialIngredients from './pages/Ingredients/OfficialIngredients'
import SuggestedIngredients from './pages/Ingredients/SuggestedIngredients'
import Home from './pages/Home'
import Ingredients from './pages/Ingredients'
import Localization from './pages/Localization'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import EmailVerification from './pages/EmailVerification'
import { model } from './api/model'
import { useSetRecoilState } from 'recoil'
import { availableLanguages } from './atoms/AvailableLanguages'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const setLanguages = useSetRecoilState(availableLanguages)
  const navigateTo = useNavigate()
  const currentLocation = useLocation()

  useEffect(() => {
    currentLocation.pathname === '/' && navigateTo('/ingredients/official')
  }, [currentLocation, navigateTo])

  const getAvailableLanguages = useCallback(async () => {
    const languages = await model.Languages.getLanguages()
    setLanguages(languages)
  }, [setLanguages])

  useEffect(() => {
    getAvailableLanguages()
  }, [getAvailableLanguages])

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Toaster />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/ingredients" element={<Ingredients />}>
              <Route path="official" element={<OfficialIngredients />} />
              <Route path="suggested" element={<SuggestedIngredients />} />
            </Route>
            <Route path="/" element={<Home />} />
            <Route path="/localization" element={<Localization />} />
          </Route>
          <Route element={<FreeRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/verify_email" element={<EmailVerification />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Layout>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
