import React from 'react'
import { useRecoilState } from 'recoil'
import { GridDetailRowProps } from '@progress/kendo-react-grid'
import { TiDeleteOutline } from 'react-icons/ti'
import uuid from 'react-uuid'

import {
  ingredientTranslation,
  translationsToAdd,
  translationsToDelete,
  translationsToEdit,
} from '../../../../../atoms/IngredientTranslationAtom'
import ThemeButton from '../../../../../components/ThemeButton'
import { IngredientTranslation } from '../../../../../types'
import useAuth from '../../../../../hooks/useAuth'

import styles from './GridDetailRow.module.scss'

const GridDetailRow = (props: GridDetailRowProps) => {
  const {
    user: { id: userId, userName },
  } = useAuth()

  const expandedRow = props.dataItem as IngredientTranslation

  const [data, setData] = useRecoilState(ingredientTranslation)
  const [toAdd, setToAdd] = useRecoilState(translationsToAdd)
  const [toEdit, setToEdit] = useRecoilState(translationsToEdit)
  const [toDelete, setToDelete] = useRecoilState(translationsToDelete)

  const addNewAlias = () => {
    const uniqueAliasId = uuid()

    const addNewAliasToData = data.translations.map((transl) => {
      if (transl.id === expandedRow.id) {
        return {
          ...transl,
          aliases: [
            ...transl.aliases,
            {
              id: uniqueAliasId,
              name: '',
              languageId: expandedRow.languageId,
            },
          ],
        }
      }
      return transl
    })

    setToAdd([
      ...toAdd,
      {
        id: uniqueAliasId,
        name: '',
        isDefault: false,
        languageId: expandedRow.languageId,
      },
    ])

    setData({ ...data, translations: addNewAliasToData })
  }

  const deleteAliasFromState = (id: string | number) => {
    const newDataTranslations = data.translations.reduce((acc, curr) => {
      const newCurr = curr.aliases.filter((alias) => alias.id !== id)
      acc.push({
        ...curr,
        aliases: newCurr,
      })
      return acc
    }, [] as IngredientTranslation[])

    if (typeof id === 'string') {
      setToAdd(toAdd.filter((translation) => translation.id !== id))
    } else if (typeof id === 'number') {
      setToEdit(toEdit.filter((translation) => translation.id !== id))
      if (!toDelete.find((itm) => itm.id === id)) {
        setToDelete([...toDelete, { id: id }])
      }
    }
    setData({ ...data, translations: newDataTranslations })
  }

  const handleSynonym = (
    e: React.FocusEvent<HTMLInputElement, Element>,
    name: string,
    id: string | number
  ) => {
    const submittedValue = e.target.value.trim() as string

    if (submittedValue === '') {
      deleteAliasFromState(id)
      return
    }

    if (name === submittedValue) {
      return
    }

    const newDataTranslations = data.translations.reduce((acc, curr) => {
      const newCurr = curr.aliases.map((alias) => {
        if (alias.id === id) {
          return {
            ...alias,
            name: submittedValue,
            creator: !alias.creator?.id
              ? {
                  id: userId,
                  userName: userName,
                }
              : { ...alias.creator },
          }
        }
        return alias
      })
      acc.push({
        ...curr,
        aliases: newCurr,
      })
      return acc
    }, [] as IngredientTranslation[])

    if (typeof id === 'string') {
      const updatedToAdd = toAdd.map((translation) =>
        translation.id === id
          ? {
              ...translation,
              name: submittedValue,
            }
          : translation
      )
      setToAdd(updatedToAdd)
    } else if (typeof id === 'number') {
      if (toEdit.find((itm) => itm.id === id)) {
        const updatedToEdit = toEdit.map((translation) => ({
          ...translation,
          name: submittedValue,
        }))
        setToEdit(updatedToEdit)
      } else {
        setToEdit([
          ...toEdit,
          {
            id: id,
            name: submittedValue,
            languageId: expandedRow.languageId,
          },
        ])
      }
    }
    setData({ ...data, translations: newDataTranslations })
  }

  return (
    <table>
      <tbody>
        <tr className={styles.expandable}>
          <td />
          <td className="oi-cell pointer aliases">
            {expandedRow.aliases.map((al) => (
              <div key={al.id} className="flex detail-row">
                <input
                  key={al.id}
                  type="text"
                  className="grid-input"
                  placeholder="Write synonym"
                  defaultValue={al.name}
                  style={{ flexShrink: 1 }}
                  onBlur={(e) => handleSynonym(e, al.name, al.id)}
                />
                {al.name && (
                  <button
                    style={{
                      border: 0,
                      backgroundColor: 'transparent',
                      padding: 0,
                      cursor: 'pointer',
                    }}
                    onClick={() => deleteAliasFromState(al.id)}
                  >
                    <TiDeleteOutline
                      color="#FF516D"
                      size="14px"
                      style={{ flexShrink: 0 }}
                    />
                  </button>
                )}
              </div>
            ))}
            <ThemeButton
              title="+ Add synonym"
              onClick={addNewAlias}
              isDisabled={expandedRow.aliases.some((alias) => !alias.name)}
            />
          </td>
          <td className="oi-cell-orange oi-cell-detail">
            {expandedRow.aliases.map((al) => (
              <div key={al.id} className={styles.expandedGridAuthor}>
                {al?.creator?.userName}
              </div>
            ))}
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  )
}

export default GridDetailRow
