import toast from 'react-hot-toast'

export type ErrorData = {
  detail: string
  status: number
  title: string
}

class HttpError extends Error {
  private readonly _status: number
  private readonly _title: string

  constructor(data: ErrorData) {
    super(data.detail)
    this._status = data.status
    this._title = data.title
  }

  public get message(): string {
    return this.message
  }
  public get status(): number {
    return this._status
  }
  public get title(): string {
    return this._title
  }
}

export function httpErrorHandler(
  error: unknown,
  callback?: (error: HttpError) => void
) {
  if (error instanceof HttpError) {
    if (callback) {
      callback(error)
      return
    }
    toast.error(error.message)
  } else {
    alert('Unexpected error occured!')
  }
}

export default HttpError
