import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import cn from 'clsx'

import Logo from '../../asset/inline/Logo.svg'
import useAuth from '../../hooks/useAuth'

import styles from './Login.module.scss'

type Inputs = {
  credential: string
  password: string
}

const Login: React.FC = () => {
  const { login } = useAuth()
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setIsLoggingIn(true)

    login(data.credential, data.password).then(() => setIsLoggingIn(false))
  }

  return (
    <main className={styles.container}>
      <div className={styles.box}>
        <Logo className={styles.logo} />
        <div className={styles.boxContent}>
          <h1 className={styles.title}>
            Welcome back!
            <br />
            We&apos;ve missed you.
          </h1>
          <p>Please login to start editing database.</p>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={styles.label}>
              <p className={styles.text}>EMAIL OR USERNAME</p>
              <input
                type="text"
                className={cn([
                  styles.input,
                  errors.credential && styles.error,
                ])}
                {...register('credential', { required: true })}
              />
              {errors.credential ? (
                <p className={styles.errorText}>
                  Email or Username is required.
                </p>
              ) : null}
            </label>
            <label className={styles.label}>
              <p className={styles.text}>PASSWORD</p>
              <input
                type="password"
                className={cn([styles.input, errors.password && styles.error])}
                {...register('password', { required: true })}
              />
              {errors.password ? (
                <p className={styles.errorText}>Password is required.</p>
              ) : null}
            </label>
            <button
              type="submit"
              value="Login"
              className={`${styles.button} ${
                isLoggingIn && styles.buttonLoading
              }`}
              disabled={isLoggingIn}
            >
              <span className={styles.buttonText}>Login</span>
            </button>
          </form>
        </div>
      </div>
    </main>
  )
}

export default Login
