import { filterBy } from '@progress/kendo-react-data-tools'
import {
  getter,
  setter,
  mapTree,
  extendDataItem,
} from '@progress/kendo-react-common'

import { IngredientTypesValue } from '../types/ingredients/IngredientTypes'

export const expandedState = (
  item: IngredientTypesValue,
  dataItemKey: string,
  expanded: number[]
) => {
  const nextExpanded = expanded.slice()
  const keyGetter = getter(dataItemKey)
  const itemKey = keyGetter(item)
  const index = expanded.findIndex((currentKey) => {
    return currentKey === itemKey
  })
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1)

  return nextExpanded
}

export const getValueMap = (value: any, idGetter: any) => {
  const map: { [index: string]: any } = {}

  if (value && value.length) {
    value.forEach((item: string) => {
      map[idGetter(item)] = true
    })
  }

  return map
}

const mapMultiSelectTreeData = (data: any, options: any) => {
  const {
    keyGetter,
    subItemGetter,
    subItemSetter,
    checkSetter,
    expandedSetter,
    checkIndeterminateSetter,
    valueMap,
    expandedMap,
  }: any = options

  if (!data || !data.length) {
    return [data, false]
  }

  let hasChecked = false
  const newData = [...data].map((dataItem) => {
    const [children, hasCheckedChildren] = mapMultiSelectTreeData(
      subItemGetter(dataItem),
      options
    )

    const isChecked = valueMap[keyGetter(dataItem)]
    if (isChecked || hasCheckedChildren) {
      hasChecked = true
    }

    const newItem = { ...dataItem }

    expandedSetter(newItem, expandedMap[keyGetter(newItem)])
    subItemSetter(newItem, children)
    checkSetter(newItem, isChecked)
    checkIndeterminateSetter(newItem, !isChecked && hasCheckedChildren)

    return newItem
  })

  return [newData, hasChecked]
}

export const processTreeData = (data: any, state: any, fields: any) => {
  const { selectField, expandField, dataItemKey, subItemsField } = fields
  const { expanded, value, filter } = state
  const filtering = Boolean(filter && filter.value)

  return mapTree(
    filtering ? filterBy(data, [filter], subItemsField) : data,
    subItemsField,
    (item) => {
      const props = {
        [expandField]: expanded?.includes(item[dataItemKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey],
      }
      return filtering
        ? extendDataItem(item, subItemsField, props)
        : { ...item, ...props }
    }
  )
}

export const processMultiSelectTreeData = (tree: any, options: any) => {
  const {
    subItemsField = 'subCategories',
    checkField = 'checked',
    checkIndeterminateField = 'checkIndeterminateField',
    expandField = 'expanded',
    dataItemKey,
    value,
    filter,
    expanded,
  }: any = options

  const keyGetter = getter(dataItemKey)
  const filtering = Boolean(filter && filter.value)
  const expandedMap: { [index: string]: any } = {}

  expanded?.forEach((id: number) => (expandedMap[id] = true))

  const [result] = mapMultiSelectTreeData(tree, {
    valueMap: getValueMap(value, keyGetter),
    expandedMap: expandedMap,
    keyGetter,
    expandedSetter: setter(expandField),
    subItemGetter: getter(subItemsField),
    subItemSetter: setter(subItemsField),
    checkSetter: setter(checkField),
    checkIndeterminateSetter: setter(checkIndeterminateField),
  })

  return filtering ? filterBy(result, [filter], subItemsField) : result
}
