import { FC } from 'react'

import Config from '../../../config/config'
import { IngredientDetails } from '../../../types'

import styles from './RecipesList.module.scss'

interface Props {
  data: IngredientDetails[] | undefined
  setCurrentRecipeInView: (recipeId: number) => void
  currentActiveItemId: number
}

const {
  photoPaths: { recipePhoto, placeholder },
} = Config

const RecipesList: FC<Props> = ({
  data,
  setCurrentRecipeInView,
  currentActiveItemId,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        {data !== undefined &&
          data.map(({ id, name, fullName, image }, index) => {
            return (
              <div
                key={index}
                className={
                  currentActiveItemId === id
                    ? `${styles.item} ${styles.isCurrentActive}`
                    : styles.item
                }
                onClick={() => setCurrentRecipeInView(id)}
              >
                <div>
                  <img
                    alt={name}
                    src={image ? `${recipePhoto}/${image}` : placeholder}
                    onError={(event: any) => {
                      event.target.src = placeholder
                    }}
                    className={styles.image}
                  />
                </div>
                <div className={styles.info}>
                  <div className={styles.title}>{name}</div>
                  <div className={styles.author}>{fullName}</div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default RecipesList
