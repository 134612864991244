export default function IngredientOccurrences({
  count,
  disabled = false,
  onClick,
}: {
  count: number
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}) {
  return (
    <td
      className={`oi-cell font-grey ${disabled && 'cell-disabled'} ${
        count > 0 && 'pointer'
      }`}
    >
      <div onClick={onClick && onClick}>{count} recipes</div>
    </td>
  )
}
