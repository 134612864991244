import React from 'react'
import { GridRowProps } from '@progress/kendo-react-grid'

export const rowRender = (
  trElement: React.ReactElement<HTMLTableRowElement>,
  props: GridRowProps
) => {
  const expanded = props.dataItem.expanded

  const trProps: any = {
    style: props.isSelected
      ? { boxShadow: '0 0 10px 5px rgba(43, 176, 168, 0.2)' }
      : {
          borderTop: '1px solid transparent',
          borderBottom: expanded ? undefined : '1px solid rgb(245, 248, 250)',
        },
  }
  return React.cloneElement(
    trElement,
    { ...trProps },
    trElement.props.children as any
  )
}
