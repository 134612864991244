import IngredientsProvider from './providers/IngredientsProvider'
import LanguagesProvider from './providers/LanguagesProvider'
import LocalizationProvider from './providers/LocalizationProvider'
import UserProvider from './providers/UserProvider'

class Model {
  private readonly auth: UserProvider
  private readonly localization: LocalizationProvider
  private readonly languages: LanguagesProvider
  private readonly ingredients: IngredientsProvider

  constructor() {
    this.auth = new UserProvider()
    this.localization = new LocalizationProvider()
    this.ingredients = new IngredientsProvider()
    this.languages = new LanguagesProvider()
  }

  public get Auth(): UserProvider {
    return this.auth
  }

  public get Localization(): LocalizationProvider {
    return this.localization
  }

  public get Ingredients(): IngredientsProvider {
    return this.ingredients
  }

  public get Languages(): LanguagesProvider {
    return this.languages
  }
}

export const model = new Model()
