import { useQuery } from '@tanstack/react-query'

import { model } from '../../../api/model'

const fiveMinutes = 1000 * 60 * 5
const staleTime = fiveMinutes

export const useGetIngredientDetailsForModalQuery = (id: number) => {
  return useQuery({
    queryFn: () =>
      id ? model.Ingredients.getIngredientDetails(id) : undefined,
    enabled: !!id,
    queryKey: ['ingredient-details', id],
    keepPreviousData: true,
    staleTime: staleTime,
  })
}

export const useGetIngredientDetailsForRecipeQuery = (id: number) => {
  return useQuery({
    queryFn: () =>
      id ? model.Ingredients.getIngredientDetailRecipe(id) : undefined,
    enabled: !!id,
    queryKey: ['ingredient-details-recipe', id],
    keepPreviousData: true,
    staleTime: staleTime,
  })
}

export const useGetIngredientDetailsForModalByNameQuery = (name: string) => {
  return useQuery({
    queryFn: () =>
      name ? model.Ingredients.getIngredientDetailsByName(name) : undefined,
    enabled: !!name,
    queryKey: ['ingredient-details', name],
    keepPreviousData: true,
    staleTime: staleTime,
  })
}
