import { useCallback, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import cn from 'clsx'

import { ingredientTypes } from '../../atoms/IngredientTypes'
import { IngredientType } from '../../types'
import Page from '../../components/Page'
import { model } from '../../api/model'

import styles from './Ingredients.module.scss'

const Ingredients = () => {
  const navigate = useNavigate()
  const setIngredientTypes = useSetRecoilState(ingredientTypes)

  const { Official, Suggested } = IngredientType
  const currentTab = location.pathname.slice(13)
  const isOfficialTabSelected = currentTab.includes(Official)

  const changeTabTo = useCallback(
    (tab: IngredientType): void => {
      navigate(`/ingredients/${tab}`)
    },
    [navigate]
  )

  const getIngredientTypes = useCallback(async () => {
    const ingrTypes = await model.Ingredients.getIngredientTypes()
    setIngredientTypes(ingrTypes)
  }, [setIngredientTypes])

  useEffect(() => {
    getIngredientTypes()
  }, [getIngredientTypes])

  useEffect(() => {
    if (!currentTab) {
      changeTabTo(Official)
    }
  }, [Official, changeTabTo, currentTab])

  return (
    <Page>
      <div className={styles.ingredients}>
        <div className={styles.tabs}>
          <div
            onClick={() => changeTabTo(Official)}
            className={cn([
              styles.tab,
              isOfficialTabSelected && styles.selected,
            ])}
          >
            <p className={styles.tabName}>Official ingredients</p>
            <div className={styles.border} />
          </div>
          <div
            onClick={() => changeTabTo(Suggested)}
            className={cn([
              styles.tab,
              !isOfficialTabSelected && styles.selected,
            ])}
          >
            <p className={styles.tabName}>Suggested ingredients</p>
            <div className={styles.border} />
          </div>
        </div>
        <Outlet />
      </div>
    </Page>
  )
}

export default Ingredients
