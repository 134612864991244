import { AxiosResponse } from 'axios'
import qs from 'qs'

import {
  Ingredient,
  IngredientLookup,
  IngredientRecipeDetail,
  IngredientToAdd,
  IngredientTranslationToAdd,
  IngredientTranslationToDelete,
  IngredientTranslationToEdit,
  OfficialIngredientLookup,
  PrivateIngredientsForSuggestion,
  SynonymToAdd,
  IngredientDetails,
  Lookup,
} from '../../../types'
import API from '../../http'
import { IngredientTypes } from '../../../types/ingredients/IngredientTypes'
import { MergeIngredientsData } from '../../../components/MergeIngredientModal/MergeIngredientModal'

type EditIngredientRequestData = {
  id: number
  name: string
  approvedByAdminPhotoId?: number
  translationsToEdit: IngredientTranslationToEdit[]
  translationsToAdd: IngredientTranslationToAdd[]
  translationsToDelete: IngredientTranslationToDelete[]
  listOfCategories: number[]
}

export type GetPrivateForSuggestionParams = {
  limit?: number
  offset?: number
  isModerated?: boolean
  name?: string
  languageId?: number
  categoryId?: number
}

export type GetOfficialIngredientParams = {
  name?: string
  offset?: number
  limit?: number
  categories?: number[]
  sortOption?: string
  isDisabled: boolean
  showIngredientsWithoutPhoto: boolean
}

export type EditSuggestedIngredientData = {
  categoryId: number
  typeId: number
  isModerated: boolean
  ingredientName: string
  newCategoryId: number
}
class IngredientsProvider {
  public async getSingleIngredient(id: string): Promise<Ingredient> {
    const { data } = await API.get('ingredients/get-single-ingredient', {
      params: { id },
    })

    return data
  }

  public async getIngredientDetailRecipe(
    id: number
  ): Promise<IngredientRecipeDetail> {
    const { data } = await API.get('ingredients/ingredient-detail-recipe', {
      params: { id },
    })

    return data
  }

  public async getIngredientDetails(id: number): Promise<IngredientDetails[]> {
    const { data } = await API.get('ingredients/ingredient-details', {
      params: { id },
    })
    return data
  }

  public async getIngredientDetailsByName(
    name: string
  ): Promise<IngredientDetails[]> {
    const { data } = await API.get('ingredients/ingredient-details', {
      params: { name },
    })
    return data
  }

  public async getIngredientsByName(
    params: GetOfficialIngredientParams
  ): Promise<OfficialIngredientLookup> {
    const { data } = await API.get('ingredients/lookup-official-ingredients', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })

    return data
  }

  public async getIngredientTypes(): Promise<IngredientTypes[]> {
    const { data } = await API.get('ingredient-types/get-ingredient-types')
    return data
  }

  public async addNewIngredient(name: string, categoryId: number) {
    const { data } = await API.post<
      { name: string; categoryId: number },
      AxiosResponse<IngredientLookup>
    >('ingredients/add-new-ingredient', { name, categoryId })

    return data
  }

  public async deleteIngredient(id: number) {
    await API.post<{ id: number }, AxiosResponse<void>>(
      'ingredients/delete-ingredient',
      { id }
    )
  }

  public async restoreIngredient(id: number) {
    await API.post<{ id: number }, AxiosResponse<void>>(
      'ingredients/restore-ingredient',
      { id }
    )
  }

  public async deleteSuggestedIngredient(name: string) {
    await API.post<{ name: string }, AxiosResponse<void>>(
      'ingredients/delete-suggested-ingredient',
      { name }
    )
  }

  public async editIngredient(data: EditIngredientRequestData) {
    await API.post<EditIngredientRequestData, AxiosResponse<void>>(
      'ingredients/edit-ingredient',
      { ...data }
    )
  }

  public async uploadIngredientImage(
    id: number,
    approvedByAdmin: boolean,
    smallImage: Blob,
    largeImage: Blob,
    credits: string | null
  ) {
    const formData = new FormData()

    formData.append('LargeImage', largeImage)
    formData.append('SmallImage', smallImage)
    formData.append('Id', id.toString())
    formData.append('approvedByAdmin', String(approvedByAdmin))
    if (credits) {
      formData.append('credits', credits)
    }

    await API.post<FormData, AxiosResponse<void>>(
      'ingredients/image-upload',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  public async deleteImage(id: number) {
    await API.post<{ id: number }, AxiosResponse<void>>(
      'ingredients/delete-image',
      { id }
    )
  }

  public async createAliasOrNewIngredient(
    body: IngredientToAdd | SynonymToAdd
  ): Promise<{ id: number }> {
    const { data } = await API.post<
      IngredientToAdd | SynonymToAdd,
      AxiosResponse<{ id: number }>
    >('ingredients/create-ingredient-from-suggestion', {
      ...body,
    })

    return data
  }

  public async getPrivateForSuggestion(params: GetPrivateForSuggestionParams) {
    const { data } = await API.get<
      { name: string },
      AxiosResponse<PrivateIngredientsForSuggestion>
    >('ingredients/get-private-ingredients-for-suggestion', {
      params,
    })
    return data
  }

  public async getLanguagesForSuggestedIngredients(): Promise<Lookup[]> {
    const { data } = await API.get(
      'ingredients/get-languages-for-suggested-ingredients'
    )
    return data
  }

  public async getListsForSuggestedIngredients(): Promise<Lookup[]> {
    const { data } = await API.get('private-ingredient-categories/lookup')
    return data
  }

  public async editSuggestedIngredient(data: EditSuggestedIngredientData) {
    await API.post<EditSuggestedIngredientData, AxiosResponse<void>>(
      'ingredients/edit-ingredient-category',
      { ...data }
    )
  }

  public async mergeOfficialIngredients(data: MergeIngredientsData) {
    await API.post<MergeIngredientsData, AxiosResponse<void>>(
      'ingredients/merge-official-ingredients',
      { ...data }
    )
  }
}

export default IngredientsProvider
