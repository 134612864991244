import { LocalizationItem, Lookup } from '../../../types'
import API from '../../http'

class LocalizationProvider {
  public async getLocalizations(
    languageId: number,
    name: string,
    filter: string,
    categoryId?: number
  ): Promise<LocalizationItem[]> {
    const { data } = await API.get('localisations/get-localisation-for-edit', {
      params: {
        languageId,
        name,
        filter,
        categoryId,
      },
    })

    return data
  }

  public async updateTranslationItem(
    item: LocalizationItem,
    languageId: number
  ): Promise<void> {
    await API.post('localisations/update-localisations', {
      localisations: [item],
      languageId,
    })
  }

  public async getCategories(): Promise<Lookup[]> {
    const { data } = await API.get('localisation-categories/lookup')
    return data
  }
}

export default LocalizationProvider
