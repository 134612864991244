import { Lookup } from '../types'
import isEmpty from 'lodash/isEmpty'
import xorWith from 'lodash/xorWith'
import isEqual from 'lodash/isEqual'

export const getIdsArrayFromLookup = (arr: Lookup[]) => {
  return arr.map((l) => l.id)
}

export const isDeepEqual = (arr1: any, arr2: any) =>
  isEmpty(xorWith(arr1, arr2, isEqual))
