import {
  getMultiSelectTreeValue,
  MultiSelectTreeChangeEvent,
  MultiSelectTreeExpandEvent,
  MultiSelectTreeFilterChangeEvent,
} from '@progress/kendo-react-dropdowns'
import { FilterDescriptor } from '@progress/kendo-data-query'

import { useCallback, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { IngredientTypesValue } from '../types/ingredients/IngredientTypes'
import {
  expandedState,
  processMultiSelectTreeData,
} from '../helpers/kendoMultiSelect'
import { ingredientTypes } from '../atoms/IngredientTypes'

export const fields = {
  dataItemKey: 'id',
  textField: 'name',
  subItemsField: 'subCategories',
  checkIndeterminateField: 'checkIndeterminateField',
  expandField: 'expanded',
  checkField: 'checked',
}

interface IMultiSelectTreeProps {
  treeData: any
  selectedIngredientTypes: IngredientTypesValue[]
  expanded: number[]
  filter: FilterDescriptor | null
  onChange: (event: MultiSelectTreeChangeEvent) => void
  onExpandChange: (event: MultiSelectTreeExpandEvent) => void
  onFilterChange: (event: MultiSelectTreeFilterChangeEvent) => void
  setSelectedIngredientTypes: React.Dispatch<
    React.SetStateAction<IngredientTypesValue[]>
  >
}

export function useMultiSelectTree(): IMultiSelectTreeProps {
  const ingredientCategoryTypes = useRecoilValue(ingredientTypes)

  const [selectedIngredientTypes, setSelectedIngredientTypes] = useState<
    IngredientTypesValue[]
  >([])
  const [expanded, setExpanded] = useState(() =>
    ingredientCategoryTypes.length
      ? [ingredientCategoryTypes[0][fields.dataItemKey]]
      : ([] as number[])
  )
  const [filter, setFilter] = useState<FilterDescriptor | null>(null)

  const onChange = (event: MultiSelectTreeChangeEvent) => {
    setSelectedIngredientTypes(
      getMultiSelectTreeValue(ingredientCategoryTypes, {
        ...fields,
        ...event,
        value: selectedIngredientTypes,
      })
    )
  }

  const onExpandChange = useCallback(
    (event: MultiSelectTreeExpandEvent) =>
      setExpanded(expandedState(event.item, fields.dataItemKey, expanded)),
    [expanded]
  )

  const treeData = useMemo(
    () =>
      processMultiSelectTreeData(ingredientCategoryTypes, {
        expanded,
        value: selectedIngredientTypes,
        filter,
        ...fields,
      }),
    [ingredientCategoryTypes, expanded, selectedIngredientTypes, filter]
  )

  const onFilterChange = (event: MultiSelectTreeFilterChangeEvent) =>
    setFilter(event.filter)

  return {
    treeData,
    selectedIngredientTypes,
    expanded,
    filter,
    onChange,
    onExpandChange,
    onFilterChange,
    setSelectedIngredientTypes,
  }
}
