import Config from '../../config/config'

const {
  photoPaths: { officialIngredient, placeholder },
} = Config

export default function OfficialIngredientImage({
  image,
  onClick,
  newSuggestions,
}: {
  image: number
  newSuggestions?: number
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}) {
  return (
    <td>
      <div className="oi-cell-container__updates-count" onClick={onClick}>
        <img
          className="cell-image"
          alt="Ingredient"
          src={image ? `${officialIngredient}/${image}` : placeholder}
          onError={(event: any) => {
            event.target.src = placeholder
          }}
        />
        {newSuggestions !== undefined && newSuggestions !== 0 && (
          <div className="updates-count-display">
            <p>{newSuggestions}</p>
          </div>
        )}
      </div>
    </td>
  )
}
