import { atom } from 'recoil'
import { Lookup } from '../types'

export const userLocalizationLanguage = atom({
  key: 'userLanguage',
  default: {
    id: 5,
    name: 'English(UK)',
  } as Lookup,
})
