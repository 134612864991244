import Page from '../../components/Page'

import styles from './Home.module.scss'

const Home = () => {
  return (
    <Page>
      <div className={styles.home}>
        <p>Home</p>
      </div>
    </Page>
  )
}

export default Home
