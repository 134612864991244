import { DropDownTree } from '@progress/kendo-react-dropdowns'

import { FC, useState } from 'react'

import { useDropdownTree, dropdownFields } from '../../../hooks/useDropdownTree'
import RoundedThemeButton from '../../RoundedThemeButton'
import { SuggestedIngredient } from '../../../types'
import Config from '../../../config/config'
import Modal from '../../Modal'

import styles from './SuggestedAddIngredientModal.module.scss'

const { photoPaths } = Config

interface Props {
  isOpen: boolean
  selectedIngredient: SuggestedIngredient | undefined
  closeModal: () => void
  createNewIngredient: (
    selectedCategoryId: number,
    selectedPhoto: number | undefined
  ) => Promise<void>
}

const SuggestedAddIngredientModal: FC<Props> = ({
  isOpen,
  selectedIngredient,
  closeModal,
  createNewIngredient,
}) => {
  const {
    treeData,
    selectedDropdownCategory,
    filter,
    onChange,
    onExpandChange,
    onFilterChange,
    setDropdownDataToInitialState,
  } = useDropdownTree()

  const [selectedPhoto, setSelectedPhoto] = useState<number>()

  const handleImageChoose = (imageId: number) => {
    if (imageId === selectedPhoto) {
      setSelectedPhoto(undefined)
      return
    }
    setSelectedPhoto(imageId)
  }

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.modal}>
        <h3 className={styles.title}>{selectedIngredient?.name}</h3>
        <div>
          <p className={styles.label}>FOR OFFICIAL LANGUAGE</p>
          <p className={styles.bold}>English</p>
        </div>
        <div className={styles.dropdown}>
          <DropDownTree
            style={{ width: '300px' }}
            placeholder="Select ingredient category..."
            data={treeData}
            value={selectedDropdownCategory}
            onChange={onChange}
            fillMode="flat"
            dataItemKey={dropdownFields.dataItemKey}
            textField={dropdownFields.textField}
            subItemsField={dropdownFields.subItemsField}
            expandField={dropdownFields.expandField}
            onExpandChange={onExpandChange}
            filterable={true}
            onFilterChange={onFilterChange}
            filter={filter.value}
          />
        </div>
        {selectedIngredient && selectedIngredient.images.length ? (
          <div className={styles.images}>
            {selectedIngredient.images.map((image) => (
              <div key={image.id} className={styles.image}>
                <img
                  src={`${photoPaths.privateIngredient}/${image.photoPath}`}
                  alt="Ingredient"
                />
                <div className={styles.info}>
                  <input
                    type="checkbox"
                    onChange={() => handleImageChoose(image.id)}
                    checked={
                      Boolean(selectedPhoto) && selectedPhoto === image.id
                    }
                  />
                  <p>@{image.authorName}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        <div className={styles.buttons}>
          <RoundedThemeButton
            onClick={() => {
              setDropdownDataToInitialState()
              closeModal()
            }}
          >
            Cancel
          </RoundedThemeButton>
          <RoundedThemeButton
            onClick={() => {
              if (selectedDropdownCategory) {
                createNewIngredient(selectedDropdownCategory.id, selectedPhoto)
                setDropdownDataToInitialState()
              }
            }}
            primary
            disabled={!selectedDropdownCategory}
          >
            Create New Ingredient
          </RoundedThemeButton>
        </div>
      </div>
    </Modal>
  )
}

export default SuggestedAddIngredientModal
