import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import cn from 'clsx'

import Logo from '../../asset/inline/Logo.svg'
import useAuth from '../../hooks/useAuth'

import styles from './Header.module.scss'

const Header: React.FC = () => {
  const navigate = useNavigate()
  const { logout, user } = useAuth()

  const handleLogout = () => {
    logout()
  }

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <Logo
          onClick={() => navigate('/ingredients')}
          className={styles.logo}
        />
        <nav>
          <ul className={styles.list}>
            {user?.token ? (
              <>
                <NavLink
                  to="/ingredients"
                  className={({ isActive }) =>
                    cn([styles.link, isActive && styles.active])
                  }
                >
                  <p>Ingredients</p>
                  <div className={styles.border} />
                </NavLink>
                <NavLink
                  to="/localization"
                  className={({ isActive }) =>
                    cn([styles.link, isActive && styles.active])
                  }
                >
                  <p>Localization</p>
                  <div className={styles.border} />
                </NavLink>
                <div
                  onClick={handleLogout}
                  className={cn([styles.link, styles.logoutBtn])}
                >
                  <p>Logout</p>
                  <div className={styles.border} />
                </div>
              </>
            ) : (
              <NavLink
                to="/login"
                className={({ isActive }) =>
                  cn([styles.link, isActive && styles.active])
                }
              >
                <p>Login</p>
                <div className={styles.border} />
              </NavLink>
            )}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
