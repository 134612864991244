import { TfiClose as CloseButton } from 'react-icons/tfi'
import { FC, useEffect, useState, useRef } from 'react'

import { IngredientDetails } from '../../types'
import { useGetIngredientDetailsForRecipeQuery } from './DetailPeek/useGetDetailPeekIngredientsData'

import DetailPeek from './DetailPeek'
import RecipesList from './RecipesList'
import Modal from '../Modal'
import CustomLoadingComponent from '../CustomLoadingComponent'
import { DetailPeekTabs } from './DetailPeek/DetailPeek'

import styles from './IngredientDetailModal.module.scss'

interface Props {
  isOpen: boolean
  closeModal: () => void
  id?: number | null
  name?: string
  data: IngredientDetails[] | undefined
}

const IngredientDetailModal: FC<Props> = ({ isOpen, closeModal, data }) => {
  const [currentRecipeInView, setCurrentRecipeInView] = useState<number>()

  useEffect(() => {
    if (data && data.length !== 0) {
      setCurrentRecipeInView(data[0].id)
    }
  }, [data])

  const handleRecipeViewChange = (id: number) => setCurrentRecipeInView(id)

  const { data: currentData, isFetching } =
    useGetIngredientDetailsForRecipeQuery(currentRecipeInView as number)

  const cleanup = () => setCurrentRecipeInView(undefined)

  useEffect(() => {
    return cleanup
  }, [])

  const modalContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node)
      ) {
        closeModal()
      }
    }

    if (isOpen) {
      window.addEventListener('mousedown', handleClickOutside)
    }

    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen, closeModal])

  return (
    <Modal isOpen={isOpen} isDetail={true}>
      <div className={styles.modal} ref={modalContentRef}>
        <div className={styles.header}>
          <h3 className={styles.title}>Recipes that contain this ingredient</h3>
          <div className={styles.button} onClick={closeModal}>
            <CloseButton size="16px" />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.list}>
            <RecipesList
              data={data}
              setCurrentRecipeInView={handleRecipeViewChange}
              currentActiveItemId={currentRecipeInView as number}
            />
          </div>
          <div className={styles.details}>
            {isFetching || currentData === null || currentData === undefined ? (
              <CustomLoadingComponent />
            ) : (
              <DetailPeek
                displayData={currentData}
                currentTab={DetailPeekTabs.Overview}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default IngredientDetailModal
