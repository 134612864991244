import { useQuery } from '@tanstack/react-query'
import { model } from '../../../api/model'

const minute = 1000 * 60
const staleTime = minute

export const useGetSingleIngredient = (id = '') => {
  return useQuery({
    enabled: !!id,
    queryKey: ['single-ingredient-data', id],
    queryFn: () => (id ? model.Ingredients.getSingleIngredient(id) : undefined),
    staleTime: staleTime,
    keepPreviousData: true,
    refetchOnMount: true,
  })
}
