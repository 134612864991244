import Config from '../../../../config/config'
import { IngredientSection } from '../../../../types'

import styles from './RecipeTab.module.scss'

const {
  photoPaths: { officialIngredient, privateIngredient, placeholder },
} = Config

const RecipeTab = ({ sections }: { sections: IngredientSection[] }) => {
  return (
    <div className={styles.detailPeekInfoRecipeContainer}>
      {sections.map((singleSection, index) => (
        <div key={index} className={styles.singleSection}>
          <h2 className={styles.sectionTitle}>{singleSection.title}</h2>
          <div className={styles.ingredientListContainer}>
            {singleSection.ingredients.map((singleIng) => {
              return (
                <div
                  className={styles.singleIngredient}
                  key={singleIng.ingredientId}
                >
                  <p className={styles.ingredientName}>{singleIng.name}</p>
                  <div className={styles.ingredientImage}>
                    {singleIng.photoPath && (
                      <img
                        src={`${
                          singleIng.privateIngredientId
                            ? privateIngredient
                            : officialIngredient
                        }/${singleIng.photoPath}`}
                        alt="ing"
                        onError={(event: any) => {
                          event.target.src = placeholder
                        }}
                      />
                    )}
                  </div>
                  <div className={styles.ingredientQuantity}>
                    <p className={styles.quantityForRecipe}>
                      {singleIng.quantityForRecipe}
                    </p>
                    <p>{singleIng.inUnitName}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <p className={styles.recipeDescription}>
            {singleSection.description}
          </p>
        </div>
      ))}
    </div>
  )
}

export default RecipeTab
