import { Dispatch, FC, SetStateAction } from 'react'
import { IoIosSearch as SearchIcon } from 'react-icons/io'

import styles from './InputSearch.module.scss'

interface IProps {
  value: string
  setValue: Dispatch<SetStateAction<string>>
  placeholder: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}

const InputSearch: FC<IProps> = ({
  value,
  setValue,
  onChange,
  placeholder,
}) => {
  return (
    <div className={styles.container}>
      <SearchIcon className={styles.icon} />
      <input
        value={value}
        onChange={(e) => {
          onChange && onChange(e)
          setValue(e.target.value)
        }}
        type="text"
        placeholder={placeholder}
        className={styles.input}
      />
    </div>
  )
}

export default InputSearch
