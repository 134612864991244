import { FC, ReactNode } from 'react'
import cn from 'clsx'

import styles from './RoundedThemeButton.module.scss'

interface Props {
  onClick: () => void
  primary?: boolean
  children: ReactNode
  disabled?: boolean
}

const RoundedThemeButton: FC<Props> = ({
  children,
  onClick,
  primary = false,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={cn([styles.button, styles[primary ? 'primary' : 'secondary']])}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default RoundedThemeButton
