import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { MultiSelectTagData } from '@progress/kendo-react-dropdowns'

import { ingredientTypes } from '../../../atoms/IngredientTypes'
import {
  IngredientTypes,
  IngredientTypesValue,
} from '../../../types/ingredients/IngredientTypes'
import { Lookup } from '../../../types'

interface TransformedSubCategories {
  id: number
  name: string
  parentCategory: Lookup
}

export const useTagData = (selectedIngredientTypes: IngredientTypesValue[]) => {
  const ingredientCategoryTypes = useRecoilValue(ingredientTypes)

  const subCategoriesWithParentCategoryNamesAsAddedField = useMemo(
    () =>
      ingredientCategoryTypes.reduce((acc, curr) => {
        curr.subCategories.map((itm) =>
          acc.push({
            id: itm.id,
            name: itm.name,
            parentCategory: { id: curr.id, name: curr.name },
          })
        )

        return acc
      }, [] as TransformedSubCategories[]),
    [ingredientCategoryTypes]
  )

  const isParentCategory = useCallback(
    (categoryId: number) => {
      return ingredientCategoryTypes.find((itm) => itm.id === categoryId)
    },
    [ingredientCategoryTypes]
  )

  const transformSelectedIngredientTypes = useMemo(() => {
    const onlyParentCategoriesFromSelectedOnes: IngredientTypes[] = []
    selectedIngredientTypes.reduce((acc, curr) => {
      if (
        isParentCategory(curr.id) &&
        !onlyParentCategoriesFromSelectedOnes.find((itm) => itm.id === curr.id)
      ) {
        onlyParentCategoriesFromSelectedOnes.push({
          id: curr.id,
          name: curr.name,
          subCategories: curr.subCategories ?? [],
        })

        return acc
      } else {
        // subcategory
        const itemFound = subCategoriesWithParentCategoryNamesAsAddedField.find(
          (itm) => itm.id === curr.id
        )

        if (itemFound) {
          const parentCategory = onlyParentCategoriesFromSelectedOnes.find(
            (itm) => itm.id === itemFound.parentCategory.id
          )
          if (
            parentCategory?.subCategories.find((itm) => itm.id === itemFound.id)
          ) {
            return acc
          }
          if (parentCategory) {
            parentCategory.subCategories = [
              ...parentCategory.subCategories,
              { id: itemFound.id, name: itemFound.name },
            ]
          } else {
            onlyParentCategoriesFromSelectedOnes.push({
              id: itemFound.parentCategory.id,
              name: itemFound.parentCategory.name,
              subCategories: [{ id: itemFound.id, name: itemFound.name }],
            })
          }
        }
        return acc
      }
    }, [] as IngredientTypes[])

    return onlyParentCategoriesFromSelectedOnes
  }, [
    isParentCategory,
    selectedIngredientTypes,
    subCategoriesWithParentCategoryNamesAsAddedField,
  ])

  const transformedDataForShowingTags = useMemo(() => {
    return transformSelectedIngredientTypes.reduce((acc, curr) => {
      return acc.concat({
        text: `${curr.name} ${
          curr.subCategories.length ? `(${curr.subCategories.length})` : ''
        }`,
        data: [curr],
      })
    }, [] as MultiSelectTagData[])
  }, [transformSelectedIngredientTypes])

  return transformedDataForShowingTags
}
