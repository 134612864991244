import { BsTrash } from 'react-icons/bs'

interface Props {
  isModerated: boolean
  onAddNewIngredientClick: () => void
  onNewSynonymClick: () => void
  onDeleteSuggestedIngredientClick: () => void
}

export default function SuggestedIngredientActions({
  onAddNewIngredientClick,
  onNewSynonymClick,
  isModerated,
  onDeleteSuggestedIngredientClick,
}: Props) {
  return (
    <td className="inline-edit-field">
      <div className="actions">
        {!isModerated ? (
          <button
            className="delete-btn pointer"
            onClick={onDeleteSuggestedIngredientClick}
          >
            <BsTrash className="icon" size="16px" />
          </button>
        ) : null}

        {!isModerated ? (
          <button className="btn btn-add" onClick={onAddNewIngredientClick}>
            + Add as a new ingredient
          </button>
        ) : null}

        <button
          className={`btn btn-rounded ${
            isModerated && 'btn-disabled'
          } background-${isModerated ? 'orange' : 'green'}`}
          disabled={isModerated}
          onClick={onNewSynonymClick}
        >
          {isModerated ? 'Moderated' : 'Create synonym'}
        </button>
      </div>
    </td>
  )
}
