import Resizer from 'react-image-file-resizer'

const resizeToBigFile = (file: File) =>
  new Promise<Blob>((resolve) => {
    Resizer.imageFileResizer(
      file,
      350,
      350,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as Blob)
      },
      'file',
      350,
      350
    )
  })

const resizeToSmallFile = (file: File) =>
  new Promise<Blob>((resolve) => {
    Resizer.imageFileResizer(
      file,
      100,
      100,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as Blob)
      },
      'file',
      100,
      100
    )
  })

export { resizeToBigFile, resizeToSmallFile }
