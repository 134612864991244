import {
  DropDownTreeChangeEvent,
  DropDownTreeExpandEvent,
  DropDownTreeFilterChangeEvent,
} from '@progress/kendo-react-dropdowns'
import { FilterDescriptor } from '@progress/kendo-data-query'

import { useCallback, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { DropdownSingleCategory } from '../types/ingredients/IngredientTypes'
import { expandedState, processTreeData } from '../helpers/kendoMultiSelect'
import { ingredientTypes } from '../atoms/IngredientTypes'

export const dropdownFields = {
  dataItemKey: 'id',
  textField: 'name',
  subItemsField: 'subCategories',
  checkIndeterminateField: 'checkIndeterminateField',
  expandField: 'expanded',
  selectField: 'selected',
}

interface IMultiSelectTreeProps {
  treeData: any
  selectedDropdownCategory: DropdownSingleCategory | null
  expanded: number[]
  filter: FilterDescriptor
  onChange: (event: DropDownTreeChangeEvent) => void
  onExpandChange: (event: DropDownTreeExpandEvent) => void
  onFilterChange: (event: DropDownTreeFilterChangeEvent) => void
  setDropdownDataToInitialState: () => void
}

export function useDropdownTree(): IMultiSelectTreeProps {
  const ingredientCategoryTypes = useRecoilValue(ingredientTypes)

  const [dropdownSingleCategory, setDropdownSingleCategory] =
    useState<DropdownSingleCategory | null>(null)
  const [dropdownExpanded, setDropdownExpanded] = useState(
    ingredientCategoryTypes.length
      ? [ingredientCategoryTypes[0][dropdownFields.dataItemKey]]
      : ([] as number[])
  )
  const [dropdownFilter, setDropdownFilter] = useState<FilterDescriptor>({
    value: '',
    operator: 'contains',
  })

  const onChange = (event: DropDownTreeChangeEvent) =>
    setDropdownSingleCategory(event.value)

  const onExpandChange = useCallback(
    (event: DropDownTreeExpandEvent) =>
      setDropdownExpanded(
        expandedState(event.item, dropdownFields.dataItemKey, dropdownExpanded)
      ),
    [dropdownExpanded]
  )

  const dropdownTreeData = useMemo(
    () =>
      processTreeData(
        ingredientCategoryTypes,
        {
          expanded: dropdownExpanded,
          value: dropdownSingleCategory,
          filter: dropdownFilter,
        },
        dropdownFields
      ),
    [
      ingredientCategoryTypes,
      dropdownExpanded,
      dropdownSingleCategory,
      dropdownFilter,
    ]
  )

  const onFilterChange = (event: DropDownTreeFilterChangeEvent) =>
    setDropdownFilter(event.filter)

  const setDropdownDataToInitialState = () => {
    setDropdownFilter({
      value: '',
      operator: 'contains',
    })
    setDropdownExpanded([])
    setDropdownSingleCategory(null)
  }

  return {
    treeData: dropdownTreeData,
    selectedDropdownCategory: dropdownSingleCategory,
    expanded: dropdownExpanded,
    filter: dropdownFilter,
    onChange,
    onExpandChange,
    onFilterChange,
    setDropdownDataToInitialState,
  }
}
