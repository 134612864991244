import React, { useEffect, useState } from 'react'
import { process, State } from '@progress/kendo-data-query'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { useRecoilState } from 'recoil'

import Page from '../../components/Page'
import KendoCell from '../../components/KendoCell'
import InputSearch from '../../components/InputSearch'
import { model } from '../../api/model'
import Filters from './Filters.json'
import { LocalizationItem, Lookup } from '../../types'
import { userLocalizationLanguage } from '../../atoms/userLanguage'

import styles from './Localization.module.scss'

const { filters: FILTERS } = Filters

const INIT_GRID_STATE: State = {
  sort: [{ field: 'englishName', dir: 'asc' }],
  skip: 0,
  take: 10,
}

const Localization: React.FC = () => {
  const [filterValue, setFilterValue] = useState(FILTERS[0])
  const [availableLaguages, setAvailableLaguages] = useState<Lookup[]>([])
  const [localizationCategories, setLocalizationCategories] = useState<
    Lookup[]
  >([])
  const [selectedCategory, setSelectedCategory] = useState<Lookup>()
  const [fetchedLocalization, setFetchedLocalization] = useState<
    LocalizationItem[]
  >([])
  const [search, setSearch] = useState('')
  const [gridData, setGridData] = useState(INIT_GRID_STATE)
  const [language, setLanguage] = useRecoilState(userLocalizationLanguage)

  const getlanguagesList = async () => {
    const { languages } = await model.Languages.getLanguagesForLocalisation()
    setAvailableLaguages(languages)
  }

  const changeLanguage = ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(value, 10)
    const newLanguage = availableLaguages.find((lang) => lang.id === id)
    if (newLanguage) {
      setLanguage(newLanguage)
    }
  }

  const changeFilterValue = ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(value, 10)
    setFilterValue(FILTERS[id])
  }

  const changeCategory = ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(value, 10)
    const category = localizationCategories.find(
      (category) => category.id === id
    )
    setSelectedCategory(category)
  }

  const getLocalizationCategories = async () => {
    const data = await model.Localization.getCategories()
    setLocalizationCategories(data)
  }

  useEffect(() => {
    getlanguagesList()
    getLocalizationCategories()
  }, [])

  useEffect(() => {
    if (!language?.id) {
      return
    }
    const timer = setTimeout(async () => {
      const localization = await model.Localization.getLocalizations(
        language.id,
        search,
        filterValue.value,
        selectedCategory?.id
      )
      setGridData(INIT_GRID_STATE)
      setFetchedLocalization(localization)
    }, 150)

    return () => clearTimeout(timer)
  }, [search, language, filterValue, selectedCategory])

  return (
    <Page>
      <div className={styles.localization}>
        <h1 className={styles.heading}>Localization</h1>
        <InputSearch
          placeholder="Search by key, language or any value"
          value={search}
          setValue={setSearch}
        />
        <div className={styles.selectBy}>
          <p>Select:</p>
          <select
            className={styles.select}
            name="Select"
            value={filterValue?.id}
            onChange={changeFilterValue}
          >
            {FILTERS.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          <p>Category:</p>
          <select
            className={styles.select}
            name="Category"
            value={selectedCategory?.id}
            onChange={changeCategory}
          >
            <option>Default(All)</option>
            {localizationCategories.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          <p>Language:</p>
          <select
            className={styles.select}
            name="Language"
            value={language?.id}
            onChange={changeLanguage}
          >
            {availableLaguages.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <Grid
          data={process(fetchedLocalization, gridData)}
          pageable
          sortable
          total={fetchedLocalization.length}
          {...gridData}
          onDataStateChange={(e) => setGridData(e.dataState)}
          style={{ minHeight: '450px' }}
          resizable
        >
          <GridColumn
            field="name"
            title={`Translation (${language?.name})`}
            cell={(props) => (
              <KendoCell {...props} column="name" languageId={language?.id} />
            )}
          />
          <GridColumn
            field="englishName"
            title="English"
            cell={(props) => <KendoCell {...props} column="englishName" />}
          />
          <GridColumn
            field="key"
            title="Key"
            cell={(props) => <KendoCell {...props} column="key" />}
          />
          <GridColumn
            field="category"
            title="Category"
            cell={(props) => <KendoCell {...props} column="category" />}
          />
        </Grid>
      </div>
    </Page>
  )
}

export default Localization
