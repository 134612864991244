import {
  Grid,
  GridColumn,
  GridDetailRowProps,
  GridExpandChangeEvent,
} from '@progress/kendo-react-grid'
import {
  MultiSelectTree,
  getMultiSelectTreeValue,
} from '@progress/kendo-react-dropdowns'
import { AiFillPlusCircle } from 'react-icons/ai'
import { VscChromeClose as CloseIcon } from 'react-icons/vsc'
import { chevronDownIcon, chevronUpIcon } from '@progress/kendo-svg-icons'
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from '@progress/kendo-react-layout'
import { Reveal } from '@progress/kendo-react-animation'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { BsThreeDots } from 'react-icons/bs'
import { FC, useState, useCallback, useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import uuid from 'react-uuid'

import { model } from '../../../../api/model'
import {
  ingredientTranslation,
  translationsToAdd,
  translationsToDelete,
  translationsToEdit,
} from '../../../../atoms/IngredientTranslationAtom'
import { Ingredient, IngredientTranslation, Lookup } from '../../../../types'
import { httpErrorHandler } from '../../../../api/HttpError'
import {
  DefaultTranslation,
  DeleteCell,
  GridHeaderCell,
  IngredientsPickerCombobox,
  OfficialIngredientLanguage,
} from '../../../../components/KendoGridElements'
import ThemeButton from '../../../../components/ThemeButton'
import { rowRender } from '../../../../components/KendoGridElements/rowRender'
import { useOfficialIngredientImage } from '../../../../hooks/useOfficialIngredientImage'
import { getIdsArrayFromLookup, isDeepEqual } from '../../../../helpers/utils'
import GridImage from './GridImage'
import GridDetailRow from './GridDetailRow'
import Config from '../../../../config/config'
import {
  fields,
  useMultiSelectTree,
} from '../../../../hooks/useMultiSelectTree'
import { IngredientTypesValue } from '../../../../types/ingredients/IngredientTypes'
import MergeIngredientModal from '../../../../components/MergeIngredientModal'
import { Tag } from '../Tag'
import { useTagData } from '../useTagData'
import { ingredientTypes } from '../../../../atoms/IngredientTypes'
import { useGetSingleIngredient } from '../useGetSingleIngredient'

import styles from './IngredientEdit.module.scss'

const { photoPaths } = Config

type Props = {
  ingredientId: number
  handleClosingSidePanel: () => void
  getIngredientName: (name: string) => void
}

type ExpansionFields = {
  settings: boolean
  translations: boolean
  photos: boolean
}

dayjs.extend(customParseFormat)

const IngredientEdit: FC<Props> = ({
  ingredientId,
  handleClosingSidePanel,
  getIngredientName,
}) => {
  const ingredientCategoryTypes = useRecoilValue(ingredientTypes)

  const [data, setData] = useRecoilState(ingredientTranslation)
  const [toAdd, setToAdd] = useRecoilState(translationsToAdd)
  const [toEdit, setToEdit] = useRecoilState(translationsToEdit)
  const [toDelete, setToDelete] = useRecoilState(translationsToDelete)
  const [ingredientForMerge, setIngredientForMerge] = useState<Lookup>()
  const [showModal, setShowModal] = useState(false)
  const [comboBoxKey, setComboBoxKey] = useState(0)

  const [initialData, setInitialData] = useState<Ingredient>({} as Ingredient)
  const [expandedFields, setExpandedFields] = useState<ExpansionFields>({
    settings: false,
    translations: true,
    photos: false,
  })

  const queryClient = useQueryClient()

  const {
    uploadedImage,
    featuredImage,
    submitedImages,
    selectedImageId,
    uploadIngredientImage,
    resetSelectedImage,
    handleImageDelete,
    handleImageCheck,
    setImageAsFeatured,
  } = useOfficialIngredientImage()

  const {
    treeData,
    selectedIngredientTypes,
    onChange,
    onExpandChange,
    onFilterChange,
    setSelectedIngredientTypes,
  } = useMultiSelectTree()

  const returnIdAndNameOnlyForComparison = (
    arr: IngredientTypesValue[]
  ): Lookup[] => arr?.map((itm) => ({ id: itm.id, name: itm.name }))

  const saveDisabled = useMemo(() => {
    const change =
      toAdd.length > 0 ||
      toEdit.length > 0 ||
      toDelete.length > 0 ||
      uploadedImage !== null ||
      selectedImageId !== undefined ||
      !isDeepEqual(
        returnIdAndNameOnlyForComparison(initialData?.categories),
        returnIdAndNameOnlyForComparison(data?.categories)
      )

    return change
  }, [
    toAdd.length,
    toEdit.length,
    toDelete.length,
    uploadedImage,
    selectedImageId,
    initialData?.categories,
    data?.categories,
  ])

  const resetChangesState = useCallback(() => {
    setToAdd([])
    setToEdit([])
    setToDelete([])
    resetSelectedImage()
  }, [resetSelectedImage, setToAdd, setToDelete, setToEdit])

  const resetTranslations = useResetRecoilState(ingredientTranslation)
  const resetToAdd = useResetRecoilState(translationsToAdd)
  const resetToEdit = useResetRecoilState(translationsToEdit)
  const resetToDelete = useResetRecoilState(translationsToDelete)

  const cleanRecoilState = useCallback(() => {
    resetTranslations()
    resetToAdd()
    resetToEdit()
    resetToDelete()
    resetSelectedImage()
  }, [
    resetTranslations,
    resetToAdd,
    resetToEdit,
    resetToDelete,
    resetSelectedImage,
  ])

  const transformCategoriesToKendoData = (arr: IngredientTypesValue[]) => {
    return arr.map((itm) => ({
      ...itm,
      checked: undefined,
      checkIndeterminateField: false,
      expanded: undefined,
    }))
  }

  const { data: singleIngredientData } = useGetSingleIngredient(
    String(ingredientId)
  )

  const getIngredient = useCallback(async () => {
    cleanRecoilState()

    if (!ingredientId || !singleIngredientData) return

    const newIng = singleIngredientData.translations.map((trans) => ({
      ...trans,
      expanded: true,
    }))
    setInitialData({
      ...singleIngredientData,
      translations: newIng,
    })
    setData({
      ...singleIngredientData,
      translations: newIng,
    })

    setComboBoxKey((prev) => {
      return prev + 1
    })
    setIngredientForMerge(undefined)
    if (singleIngredientData.images.length === 0) {
      setExpandedFields((_prev) => {
        return {
          ..._prev,
          photos: true,
        }
      })
    }
    getIngredientName(singleIngredientData.name)

    setSelectedIngredientTypes(
      transformCategoriesToKendoData(singleIngredientData.categories)
    )
  }, [
    ingredientId,
    setData,
    setSelectedIngredientTypes,
    cleanRecoilState,
    singleIngredientData,
    getIngredientName,
  ])

  useEffect(() => {
    getIngredient()
  }, [getIngredient])

  const addNewTranslation = () => {
    const uniqueIdAsAString = uuid()

    const newTranslation: IngredientTranslation = {
      id: uniqueIdAsAString,
      name: '',
      language: '',
      languageId: 0,
      isDefault: true,
      aliases: [],
    }

    setData({
      ...data,
      translations: [...data.translations, newTranslation],
    })

    setToAdd([
      ...toAdd,
      {
        id: uniqueIdAsAString,
        name: '',
        isDefault: true,
      },
    ])
  }

  const updateIngredientTranslationList = (
    newTranslations: IngredientTranslation[]
  ) => {
    setData((prevState) => ({
      ...prevState,
      translations: newTranslations,
    }))
  }

  const popFromList = (id: number | string) => {
    const updatedIngredientTranslationList = data.translations.filter(
      (translation) => translation.id !== id
    )
    updateIngredientTranslationList(updatedIngredientTranslationList)
  }

  const deleteTranslationFromDb = async (id: number | string) => {
    try {
      if (typeof id === 'number') {
        setToEdit(toEdit.filter((trans) => trans.id !== id))
        setToDelete([...toDelete, { id: id }])
      } else if (typeof id === 'string') {
        setToAdd(toAdd.filter((trans) => trans.id !== id))
      }
      popFromList(id)
    } catch (error) {
      httpErrorHandler(error)
    }
  }

  const handleDataSave = async () => {
    if (data.categories.length === 0) {
      toast.error('Ingredient categories cannot be empty!')
    } else {
      try {
        await model.Ingredients.editIngredient({
          id: data.id,
          name: data.name,
          approvedByAdminPhotoId:
            typeof selectedImageId === 'number' ? selectedImageId : undefined,
          translationsToAdd: toAdd.filter((trans) => trans.name),
          translationsToEdit: toEdit.filter((trans) => trans.name),
          translationsToDelete: toDelete,
          listOfCategories: getIdsArrayFromLookup(data.categories),
        })

        await setImageAsFeatured()
        queryClient.invalidateQueries(['getIngredientsByName'])
        queryClient.invalidateQueries(['single-ingredient-data'])
        toast.success('Ingredient updated!')
      } catch (error) {
        httpErrorHandler(error)
      } finally {
        await getIngredient()
        resetChangesState()
      }
    }
  }

  const expandChange = (e: GridExpandChangeEvent) => {
    const newData = data.translations.map((itm) => {
      if (itm.id === e.dataItem.id && e.dataItem.name) {
        return { ...itm, expanded: !e.dataItem.expanded }
      }
      return itm
    })
    setData({ ...data, translations: newData })
  }

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const expansionFieldsHandler = (fieldName: string) =>
    setExpandedFields({
      ...expandedFields,
      [fieldName]: !expandedFields[fieldName],
    })

  const tagData = useTagData(selectedIngredientTypes)

  const handleAfterMerge = () => {
    queryClient.invalidateQueries(['getIngredientsByName'])
    queryClient.invalidateQueries(['single-ingredient-data'])
    setIngredientForMerge(undefined)
    document.body.style.overflow = 'scroll'
    setComboBoxKey(comboBoxKey + 1)
  }

  return (
    <div className={styles.ingredientEdit}>
      <div className={styles.actionsContainer}>
        {ingredientForMerge && (
          <MergeIngredientModal
            isOpen={showModal}
            closeModal={closeModal}
            currentIngredient={{ id: data?.id, name: data?.name }}
            ingredientForMerge={ingredientForMerge}
            handleAfterMerge={handleAfterMerge}
          />
        )}
        <div className={styles.buttonAndUsername}>
          <div onClick={handleClosingSidePanel} className={styles.closeButton}>
            <CloseIcon />
          </div>

          {data?.ingredientCreator?.userName && (
            <div className={styles.authorInfo}>
              <h3>
                <span>Created:</span>
                {`${dayjs(data.createdAt).format('D MMM YYYY')} by @${
                  data?.ingredientCreator?.userName
                }`}
              </h3>
              <h3>
                <span>Last update:</span>
                {dayjs(data?.lastEditedOn).format('D MMM YYYY')}
              </h3>
            </div>
          )}
        </div>
        <div className={styles.moreActionsAndSave}>
          <div className={styles.moreActions}>
            <BsThreeDots />
          </div>

          <button
            disabled={!saveDisabled}
            onClick={handleDataSave}
            className={styles.saveBtn}
          >
            Save
          </button>
        </div>
      </div>

      <div className={styles.container}>
        <div
          className={
            !saveDisabled
              ? styles.mainInfo
              : `${styles.mainInfo} ${styles.isEdited}`
          }
        >
          <div className={styles.headingContainer}>
            <h1 className={styles.name}>{data.name}</h1>
          </div>
        </div>
      </div>

      <div className={styles.expanderContainer}>
        <ExpansionPanel
          title="Settings"
          subtitle={expandedFields.settings ? '' : 'Category, Merge'}
          expandSVGIcon={chevronDownIcon}
          collapseSVGIcon={chevronUpIcon}
          expanded={expandedFields.settings}
          onAction={() => expansionFieldsHandler('settings')}
        >
          <Reveal>
            {expandedFields.settings && (
              <ExpansionPanelContent onKeyDown={(e) => e.stopPropagation()}>
                <div className="sidepanel-multiselect">
                  <p className={styles.settingsLabel}>Category: </p>
                  <div className={'flex multiselect-ingredient-edit'}>
                    <MultiSelectTree
                      size="small"
                      fillMode="flat"
                      placeholder="Select ingredient categories..."
                      className="multiselect-tree"
                      popupSettings={{ popupClass: 'multiselect-popup' }}
                      data={treeData}
                      value={selectedIngredientTypes}
                      defaultValue={initialData.categories}
                      tags={tagData}
                      tag={(props) => (
                        <Tag
                          {...props}
                          selectedIngredientTypes={selectedIngredientTypes}
                          setSelectedIngredientTypes={
                            setSelectedIngredientTypes
                          }
                        />
                      )}
                      dataItemKey={fields.dataItemKey}
                      textField={fields.textField}
                      subItemsField={fields.subItemsField}
                      checkField={fields.checkField}
                      expandField={fields.expandField}
                      checkIndeterminateField={fields.checkIndeterminateField}
                      onChange={(e) => {
                        onChange(e)
                        setData({
                          ...data,
                          categories: getMultiSelectTreeValue(
                            ingredientCategoryTypes,
                            {
                              ...fields,
                              ...e,
                              value: selectedIngredientTypes,
                            }
                          ),
                        })
                      }}
                      onExpandChange={onExpandChange}
                      filterable={true}
                      onFilterChange={onFilterChange}
                    />
                  </div>
                </div>
                <div className={styles.mergeIngredient}>
                  <p className={styles.settingsLabel}>Merge:</p>
                  <div>
                    <IngredientsPickerCombobox
                      name={''}
                      handleItemClick={(item: Lookup) =>
                        setIngredientForMerge(item)
                      }
                      defaultValue={{ id: 0, name: '' }}
                      placeholder="Type ingredient"
                      comboBoxKey={comboBoxKey}
                    />

                    {ingredientForMerge && (
                      <button className={styles.mergeBtn} onClick={openModal}>
                        Merge ingredient
                      </button>
                    )}
                  </div>
                </div>
              </ExpansionPanelContent>
            )}
          </Reveal>
        </ExpansionPanel>

        <ExpansionPanel
          title="Translations"
          subtitle={
            expandedFields.translations ? '' : 'Translations, Synonyms, Authors'
          }
          expandSVGIcon={chevronDownIcon}
          collapseSVGIcon={chevronUpIcon}
          expanded={expandedFields.translations}
          onAction={() => expansionFieldsHandler('translations')}
        >
          <Reveal>
            {expandedFields.translations && (
              <ExpansionPanelContent>
                <div className={styles.gridContainer}>
                  <Grid
                    data={data.translations}
                    style={{ minHeight: '150px' }}
                    resizable
                    rowRender={rowRender}
                    rowHeight={50}
                    onExpandChange={expandChange}
                    detail={(props: GridDetailRowProps) => (
                      <GridDetailRow {...props} />
                    )}
                    expandField="expanded"
                    className="ingredient-edit-grid"
                    onKeyDown={(e) => e.syntheticEvent.stopPropagation()}
                  >
                    <GridColumn
                      field="language"
                      headerCell={() => <GridHeaderCell name="LANGUAGE" />}
                      cell={({
                        dataItem: { languageId, id },
                      }: {
                        dataItem: IngredientTranslation
                      }) => (
                        <OfficialIngredientLanguage
                          id={id}
                          languageId={languageId}
                        />
                      )}
                    />
                    <GridColumn
                      field="translation"
                      headerCell={() => <GridHeaderCell name="TRANSLATION" />}
                      cell={({
                        dataItem: {
                          id,
                          name: translation,
                          aliases,
                          languageId,
                        },
                      }: {
                        dataItem: IngredientTranslation
                      }) => (
                        <DefaultTranslation
                          id={id}
                          translation={translation}
                          aliases={aliases}
                          languageId={languageId}
                        />
                      )}
                    />
                    <GridColumn
                      field="creator.userName"
                      headerCell={() => <GridHeaderCell name="AUTHOR" />}
                      className="oi-cell-orange"
                    />
                    <GridColumn
                      field="action"
                      headerCell={() => <GridHeaderCell name="ACTION" />}
                      cell={({
                        dataItem: { id, languageId, aliases },
                      }: {
                        dataItem: IngredientTranslation
                      }) =>
                        languageId !== 5 ? (
                          <DeleteCell
                            id={id}
                            isDisabled={!!aliases.length}
                            onDeleteClick={deleteTranslationFromDb}
                          />
                        ) : (
                          <div />
                        )
                      }
                      width={75}
                    />
                  </Grid>
                  <ThemeButton
                    title="+ Add new language"
                    onClick={addNewTranslation}
                    isDisabled={data?.translations?.some(
                      (trans) => !trans.name || !trans.languageId
                    )}
                    style={{ padding: '1.5em 0.7em' }}
                  />
                </div>
              </ExpansionPanelContent>
            )}
          </Reveal>
        </ExpansionPanel>

        <ExpansionPanel
          title="Photos"
          subtitle={
            expandedFields.photos ? '' : 'Official photo, Photo suggestions'
          }
          onAction={() => expansionFieldsHandler('photos')}
          expandSVGIcon={chevronDownIcon}
          collapseSVGIcon={chevronUpIcon}
          expanded={expandedFields.photos}
        >
          <Reveal>
            {expandedFields.photos && (
              <ExpansionPanelContent>
                <div className={styles.images}>
                  <div className={styles.officialPhotoContainer}>
                    <div className={styles.photosHeader}>
                      <h3>Official photo</h3>
                    </div>

                    <div>
                      <img
                        className={styles.featured}
                        src={
                          featuredImage
                            ? `${photoPaths.officialIngredient}/${featuredImage?.image}`
                            : photoPaths.placeholder
                        }
                        onError={(event: any) => {
                          event.target.src = photoPaths.placeholder
                        }}
                        alt="Featured ingredient"
                      />
                    </div>
                  </div>

                  <div className={styles.suggestedPhotosContainer}>
                    <div className={styles.photosHeader}>
                      <h3>Photo suggestions</h3>
                    </div>
                    <div className={styles.imagesGrid}>
                      {submitedImages?.map((image) => (
                        <GridImage
                          key={image.id}
                          select={handleImageCheck}
                          deleteImage={handleImageDelete}
                          image={image}
                          checked={selectedImageId === image.id}
                        />
                      ))}
                      <div className={styles.imageUpload}>
                        <input
                          id="ingredient-image"
                          type="file"
                          name="ingredient-image"
                          accept="image/*"
                          onChange={uploadIngredientImage}
                        />

                        <label htmlFor="ingredient-image">
                          <AiFillPlusCircle />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </ExpansionPanelContent>
            )}
          </Reveal>
        </ExpansionPanel>
      </div>
    </div>
  )
}

export default IngredientEdit
