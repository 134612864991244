import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from '@progress/kendo-react-dropdowns'
import { FC, useCallback, useEffect, useState } from 'react'

import { model } from '../../api/model'
import { Lookup } from '../../types'

let autocompleteCache = {}

interface Props {
  name: string
  handleItemClick: (item: Lookup, name: string) => void
  noDataRender?: (
    element: React.ReactElement<HTMLDivElement>,
    currentFilterValue: string
  ) => React.ReactElement<
    HTMLDivElement,
    string | React.JSXElementConstructor<any>
  >
  comboBoxKey?: number
  handleTouch?: (name: string) => void
}

export const IngredientsPickerCombobox: FC<Props & ComboBoxProps> = ({
  disabled,
  handleItemClick,
  name,
  defaultValue,
  noDataRender,
  placeholder,
  comboBoxKey,
  handleTouch,
}) => {
  const [foundedIngredients, setFoundedIngredients] = useState<Lookup[]>([])
  const [ingredientSearch, setIngredientSearch] = useState('')

  const filterIngredientsChange = ({
    filter: { value },
  }: ComboBoxFilterChangeEvent) => {
    handleTouch && handleTouch(name)
    setIngredientSearch(value)
  }

  const handleIngredientChoose = ({ target }: ComboBoxChangeEvent) => {
    handleItemClick(target.value, name)
  }

  const fetchIngredientsByName = useCallback(async (name: string) => {
    const cachedValue = autocompleteCache[name]

    if (cachedValue) {
      setFoundedIngredients(cachedValue)
      return
    }

    const data = await model.Ingredients.getIngredientsByName({
      name,
      limit: 20,
      offset: 0,
      isDisabled: false,
      categories: [],
      showIngredientsWithoutPhoto: false,
    })

    const lookup: Lookup[] = data.ingredients.map(
      ({ id, name }) => ({ name, id } as Lookup)
    )
    setFoundedIngredients(lookup)
    autocompleteCache = { ...autocompleteCache, [name]: lookup }
  }, [])

  useEffect(() => {
    if (!ingredientSearch) {
      setFoundedIngredients([])
      return
    }

    const timer = setTimeout(async () => {
      await fetchIngredientsByName(ingredientSearch)
    }, 250)

    return () => clearTimeout(timer)
  }, [ingredientSearch, fetchIngredientsByName])

  return (
    <ComboBox
      className="grid-autocomplete"
      popupSettings={{ popupClass: 'autocomplete-popup' }}
      data={foundedIngredients}
      filterable
      disabled={disabled}
      onFilterChange={filterIngredientsChange}
      onChange={handleIngredientChoose}
      textField="name"
      defaultValue={defaultValue}
      dataItemKey="id"
      placeholder={placeholder}
      listNoDataRender={(element) =>
        noDataRender !== undefined
          ? noDataRender(element, ingredientSearch)
          : undefined
      }
      key={comboBoxKey}
    />
  )
}
